import { Component } from "react";
import {
  isAuthenticated,
  getCurrentUserId
} from "./authentification";
import { imageContainerUrl, dogPlaceHolder } from "./apiServer";
import { ajaxCaller, ajaxNonJsonCaller, authHeaders, authHeadersForFiles } from "./ajaxCaller";

export default class AuthComponent extends Component {
  currentUserId = null;

  authHeaders = null;

  authHeadersForFiles = null;

  apiLink = null;

  imageUrl = null;

  ajaxCall = null;

  ajaxNonJsonCall = null;

  constructor(props) {
    super(props);

    if (!isAuthenticated()) {
      window.location.href = "/";
    } else {
      this.currentUserId = getCurrentUserId();

      this.imageUrl = imageContainerUrl;

      this.ajaxCall = ajaxCaller;

      this.ajaxNonJsonCall = ajaxNonJsonCaller;

      this.dogPlaceHolder = dogPlaceHolder;

      this.userPlaceHolder = imageContainerUrl + "/common/userplaceholder.svg";

      this.authHeaders = authHeaders;

      this.authHeadersForFiles = authHeadersForFiles;
    }
  }
}
