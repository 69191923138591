import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class SuccessfullMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMessage: false,
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div class="alert alert-success" role="alert">
        {t("Сохранение прошло успешно")}
      </div>
    );
  }
}

export default withTranslation()(SuccessfullMessage);
