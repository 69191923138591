

import React, { Component } from "react";
import Octicon, { CommentDiscussion } from "@primer/octicons-react";
import { withTranslation } from 'react-i18next';

class DiscussionButton extends Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <span
          title={t("Написать владельцу")}
          class="clickableIcon mr-2"
          onClick={this.props.onClick}
        >
          <Octicon icon={CommentDiscussion} size="medium" />
        </span>
      </>
    );
  }
}

export default withTranslation()(DiscussionButton)