import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import ArrowLeftButton from "../../components/arrowLeftButton";
import ArrowRightButton from "../../components/arrowRightButton";
import "./dogs.css";
import { withTranslation } from 'react-i18next';
import PleaseRegisterButton from '../../components/pleaseRegisterButton';
import { Helmet } from 'react-helmet'

class Dog extends NonAuthComponent {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      dogId: id,
      dog: { dogPhotos: [], showResults: [] }
    };
  }

  getDogPublicInfo = () => {
    if (this.state.dogId === 0) {
      return;
    }

    this.ajaxCall("DogPublic/GetDogPublicInfo/" + this.state.dogId, {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        dog: response
      });
    });
  };

  componentDidMount() {
    this.getDogPublicInfo();
  }

  render() {
    const { t } = this.props;

    const dog = this.state.dog;

    return (
      <div id="dog" className="container">
        <div class="one">
          <h1>{dog.breed} {dog.name ? (" - " + dog.name) : ""}</h1>
        </div>    

        <Helmet>
          <title>{dog.breed + (dog.name ? (" - " + dog.name) : "")}</title>
          <meta name="description" content={`${dog.breed} ${(dog.name ? (" - " + dog.name) : "")} ${dog.kennelOrganization} ${dog.breeder}`.replaceAll("null", "")}></meta>
        </Helmet>

        <div id="carousel" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            {dog.dogPhotos &&
              dog.dogPhotos.length > 0 &&
              dog.dogPhotos.map(photo => (
                <li
                  data-target="#carousel"
                  data-slide-to={dog.dogPhotos.indexOf(photo)}
                  class={dog.dogPhotos.indexOf(photo) === 0 ? "active" : ""}
                ></li>
              ))}
          </ol>
          <div class="carousel-inner">
            {dog.dogPhotos &&
              dog.dogPhotos.length > 0 &&
              dog.dogPhotos.map(photo => (
                <div
                  class={
                    "carousel-item" +
                    (dog.dogPhotos.indexOf(photo) === 0 ? " active" : "")
                  }
                >
                  <img
                    src={
                      this.imageUrl +
                      "dogphotos/" +
                      photo
                    }
                    class="center-block"
                    alt={dog.breed + " - " + dog.name}
                  ></img>
                </div>
              ))}
          </div>

          {dog.dogPhotos && dog.dogPhotos.length > 1 && (
            <>
              <a
                class="carousel-control-prev"
                href="#carousel"
                role="button"
                data-slide="prev"
              >
                <ArrowLeftButton></ArrowLeftButton>
              </a>
              <a
                class="carousel-control-next"
                href="#carousel"
                role="button"
                data-slide="next"
              >
                <ArrowRightButton></ArrowRightButton>
              </a>
            </>
          )}
        </div>

        {this.currentUserId == null && (
          <PleaseRegisterButton text="Для общения с владельцем собаки пожалуйста зарегистрируйтесь">PleaseRegisterButton</PleaseRegisterButton>
        )}

        <table class="table table-striped mt-5">
          <thead>
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                {t("Основное")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("Порода")}</td>
              <td>{dog.breed}</td>
            </tr>
            <tr>
              <td>{t("Кличка")}</td>
              <td>{dog.name}</td>
            </tr>
            <tr>
              <td>{t("Пол")}</td>
              <td>{dog.gender}</td>
            </tr>
            <tr>
              <td>{t("Документы")}</td>
              <td>{dog.kennelOrganization}</td>
            </tr>
            <tr>
              <td>{t("Дата рождения")}</td>
              <td>{dog.birthDate}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-striped mt-5">
          <thead>
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                {t("Происхождение")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("Мать")}</td>
              <td>{dog.mother}</td>
            </tr>
            <tr>
              <td>{t("Отец")}</td>
              <td>{dog.father}</td>
            </tr>
            <tr>
              <td>{t("Заводчик")}</td>
              <td>{dog.breeder}</td>
            </tr>
            <tr>
              <td>{t("Питомник")}</td>
              <td>{dog.kennel}</td>
            </tr>
            {dog.pedigreeDatabaseLink && (
              <tr>
                <td>{t("Ссылка на родословную")}</td>
                <td>
                  <a href={dog.pedigreeDatabaseLink}>{t("Перейти")}</a>
                </td>
            </tr>
            )}

          </tbody>
        </table>

        <table class="table table-striped table-hover mt-5">
          <thead>
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                {t("Титулы и тесты")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("Дрессировка")}</td>
              <td>{dog.training}</td>
            </tr>
            <tr>
              <td>{t("Выставочные титулы")}</td>
              <td>{dog.titles}</td>
            </tr>
            <tr>
              <td>{t("Тесты на породные заболевания")}</td>
              <td>{dog.tests}</td>
            </tr>
          </tbody>
        </table>

        {dog.allowToShowDogShowResults && (
          <table class="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th scope="col">{t("Дата")}</th>
                <th scope="col">{t("Город")}</th>
                <th scope="col">{t("Название")}</th>
                <th scope="col">{t("Эксперт")}</th>
                <th scope="col" title={t("№ по каталогу")}>
                  №
                </th>
                <th scope="col">{t("Класс")}</th>
                <th scope="col">{t("Результат")}</th>
              </tr>
            </thead>
            <tbody>
              {dog.showResults &&
                dog.showResults.map(result => (
                  <tr>
                    <td>{result.showDate}</td>
                    <td>{result.city}</td>
                    <td>{result.showName}</td>
                    <td>{result.expert}</td>
                    <td>{result.catalogNo}</td>
                    <td>{result.dogClass}</td>
                    <td>{result.result}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default withTranslation()(Dog)