import React from "react";
import AuthComponent from "../../../authComponent";
import { handleInputChange } from "../../../inputUtilities";
import TerraDatePicker from "../../../components/terraDatePicker";
import Octicon, { FileSymlinkFile, Trashcan } from "@primer/octicons-react";
import { convertToDate } from "../../../date";
import DeleteButton from "../../../components/deleteButton";
import { withTranslation } from 'react-i18next';

class Breeding extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      dogBreedingId: this.props.dogBreedingId,
      heatStartDate: null,
      firstBreedingDate: null,
      secondBreedingDate: null,
      estimatedСhildBirth: null,
      matedDog: "",
      dogPhotos: [],
      displayBreedingInMyKennel: true,
      breedingInfo: ""
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  deleteBreedingPhoto = photoName => {
    this.ajaxCall("Image/DeleteImage/dogbreedingphotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogPhotos: this.state.dogPhotos.filter(a => a !== response.imageName)
      });
    });
  };

  rotateBreedingPhoto = photoName => {
    this.ajaxCall("Image/RotateImage/dogbreedingphotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(() => {
      const hashResult = new Date().getTime();
      this.setState({
        imageHash: hashResult
      });
    });
  };

  uploadDogBreedingPhoto = event => {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    var formData = new FormData();
    formData.append(`photo`, file);

    this.ajaxCall(
      "Image/UploadImage/DogBreedingPhotos/" +
        (this.state.dogId == null ? "0" : this.state.dogId),
      {
        method: "post",
        headers: this.authHeadersForFiles,
        body: formData
      }
    ).then(response => {
      let dogPhotos = this.state.dogPhotos;
      if (dogPhotos == null) {
        dogPhotos = [];
      }

      dogPhotos.push(response.fileName);

      this.setState({
        dogPhotos: dogPhotos
      });
    });
  };

  handleCalendarChangeHeatStartDate = date => {
    this.setState({
      heatStartDate: date
    });
  };

  handleCalendarChangeFirstBreedingDate = date => {
    this.setState({
      firstBreedingDate: date
    });
  };

  handleCalendarChangeSecondBreedingDate = date => {
    this.setState({
      secondBreedingDate: date
    });
  };

  handleCalendarChangeEstimatedСhildBirth = date => {
    this.setState({
      estimatedСhildBirth: date
    });
  };

  deleteDogBreeding = dogBreedingId => {
    this.ajaxCall("Dog/DeleteDogBreeding", {
      method: "post",
      headers: this.authHeaders,
      body: this.state.dogBreedingId
    }).then(response => {
      this.props.refreshBreedings();
      this.props.cancelEditingDogBreeding();
    });
  };

  getBreeding = () => {
    if (this.props.dogBreedingId === 0) {
      this.setState({
        heatStartDate: null,
        firstBreedingDate: null,
        secondBreedingDate: null,
        estimatedСhildBirth: null,
        matedDog: "",
        breedingInfo: "",
        displayBreedingInMyKennel: true,
        dogPhotos: []
      });
    } else {
      this.ajaxCall("Dog/GetDogBreeding/" + this.props.dogBreedingId, {
        method: "get",
        headers: this.authHeaders
      }).then(dogBreeding => {
        this.setState({
          heatStartDate: convertToDate(dogBreeding.heatStartDate),
          firstBreedingDate: convertToDate(dogBreeding.firstBreedingDate),
          secondBreedingDate:
            dogBreeding.secondBreedingDate == null ||
            dogBreeding.secondBreedingDate === ""
              ? ""
              : convertToDate(dogBreeding.secondBreedingDate),
          estimatedСhildBirth: convertToDate(dogBreeding.estimatedСhildBirth),
          matedDog: dogBreeding.matedDog,
          displayBreedingInMyKennel: dogBreeding.displayBreedingInMyKennel,
          breedingInfo: dogBreeding.breedingInfo,
          dogPhotos: dogBreeding.photos
        });
      });
    }
  };

  saveBreeding = () => {
    const bodyJson = {
      id: this.state.dogBreedingId,
      dogId: this.props.dogId,
      heatStartDate:
        this.state.heatStartDate == null
          ? null
          : this.state.heatStartDate.toLocaleDateString("en-US"),
      firstBreedingDate:
        this.state.firstBreedingDate == null
          ? null
          : this.state.firstBreedingDate.toLocaleDateString("en-US"),
      secondBreedingDate:
        this.state.secondBreedingDate == null
          ? null
          : this.state.secondBreedingDate.toLocaleDateString("en-US"),
      estimatedСhildBirth:
        this.state.estimatedСhildBirth == null
          ? null
          : this.state.estimatedСhildBirth.toLocaleDateString("en-US"),
      matedDog: this.state.matedDog,
      displayBreedingInMyKennel: this.state.displayBreedingInMyKennel,
      displayLitterInMyKennel: true,
      breedingInfo: this.state.breedingInfo,
      photos: this.state.dogPhotos
    };

    this.ajaxCall("Dog/SaveDogBreeding", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      this.props.refreshBreedings();
      this.props.cancelEditingDogBreeding();
    });
  };

  componentDidMount() {
    this.getBreeding();
  }

  render() {
    const { t } = this.props;

    const {
      heatStartDate,
      firstBreedingDate,
      secondBreedingDate,
      estimatedСhildBirth,
      matedDog
    } = this.state;

    const savingDisabled = firstBreedingDate == null || matedDog == null || matedDog === '';

    return (
      <div id="dogBreeding">
        <div class="breedingPhotos">
          {this.state.dogPhotos &&
            this.state.dogPhotos.map((dogPhoto, idx) => (
              <div className="card customCard mr-3 mb-3 dogBreedingPhoto">
                <div class="photoActions">
                  <span
                    title="Перевернуть фото"
                    class="clickableIcon mr-2"
                    onClick={() => this.rotateBreedingPhoto(dogPhoto)}
                  >
                    <Octicon icon={FileSymlinkFile} size="medium" />
                  </span>
                  <span
                    title="Удалить фото"
                    class="clickableIcon mr-2"
                    onClick={() => this.deleteBreedingPhoto(dogPhoto)}
                  >
                    <Octicon icon={Trashcan} size="medium" />
                  </span>
                </div>
                <img
                  src={
                    this.imageUrl +
                    "dogbreedingphotos/" +
                    dogPhoto +
                    "?" +
                    this.state.imageHash
                  }
                  className="card-img breedingPhoto mr-2 mb-2"
                  alt=""
                ></img>
              </div>
            ))}
          {(this.state.dogPhotos == null ||
            this.state.dogPhotos.length < 3) && (
            <label
              for="dogBreedingPhoto"
              className="card customCard mr-3 mb-3 addImageButton"
            >
              <img src={this.dogPlaceHolder} alt="..."></img>
              <div className="card-body">
                <h5 className="card-title">{t("Добавить фото")}...</h5>
              </div>
              <input
                type="file"
                class="form-control-file"
                id="dogBreedingPhoto"
                name="dogBreedingPhoto"
                style={{ visibility: "hidden" }}
                onChange={this.uploadDogBreedingPhoto}
              ></input>
            </label>
          )}
        </div>

        <div className="col-sm-6 pl-0 mt-3" style={{ clear: "both" }}>
          {this.props.isMale === false && (
            <div class="form-group">
              <label for="genderId" className="mr-2 blockElement">
                {t("Начало течки")}
              </label>
              <TerraDatePicker
                selected={heatStartDate}
                onChange={this.handleCalendarChangeHeatStartDate}
                className="form-control"
              />
            </div>
          )}

          <div class="form-group">
            <label for="genderId" className="mr-2 blockElement">
              {t("Первая вязка")}
            </label>
            <TerraDatePicker
              selected={firstBreedingDate}
              onChange={this.handleCalendarChangeFirstBreedingDate}
              className="form-control"
            />
          </div>

          <div class="form-group">
            <label for="genderId" className="mr-2 blockElement">
              {t("Контрольная вязка")}
            </label>
            <TerraDatePicker
              selected={secondBreedingDate}
              onChange={this.handleCalendarChangeSecondBreedingDate}
              className="form-control"
            />
          </div>

          <div class="form-group">
            <label
              for="genderId"
              className="mr-2 blockElement"
              title="Предполагаемая дата родов"
            >
              {t("ПДР")}
            </label>
            <TerraDatePicker
              selected={estimatedСhildBirth}
              onChange={this.handleCalendarChangeEstimatedСhildBirth}
              className="form-control"
            />
          </div>

          <div class="form-group">
            <label for="name">
              {this.props.isMale === true ? "Кличка суки" : "Кличка кобеля"}
            </label>
            <input
              type="text"
              class="form-control"
              id="matedDog"
              name="matedDog"
              autocomplete="off"
              value={matedDog}
              onChange={this.handleInputChange}
            ></input>
          </div>

          <div class="form-group">
            <label for="breedingInfo">{t("Информация о паре")}</label>
            <textarea
              class="form-control"
              id="breedingInfo"
              name="breedingInfo"
              value={this.state.breedingInfo}
              onChange={this.handleInputChange}
              rows="5"
              maxLength="2000"
            ></textarea>
          </div>

          {this.props.hasKennel && (
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                autocomplete="off"
                id="displayBreedingInMyKennel"
                name="displayBreedingInMyKennel"
                onChange={this.handleInputChange}
                checked={this.state.displayBreedingInMyKennel}
              ></input>
              <label class="form-check-label" for="displayBreedingInMyKennel">
                {t("тображать в разделе моего питомника")}
              </label>
            </div>
          )}
        </div>

        <div className="actionButtons">
          <button
            type="button"
            onClick={this.saveBreeding}
            disabled={savingDisabled}
            className="btn btn-primary mt-3 mr-2"
          >
            {t("Сохранить")}
          </button>

          <button
            type="button"
            onClick={this.props.cancelEditingDogBreeding}
            className="btn btn-secondary mt-3 mr-2"
          >
            {t("Отмена")}
          </button>

          <DeleteButton
            asIcon={false}
            uniqueDialogId="deleteBreedingDog"
            onClick={this.deleteDogBreeding}
          ></DeleteButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Breeding)