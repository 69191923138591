import React, { Component } from "react";
import HandlerEventDetails from "./handlerEventDetails";
import { ajaxCaller, authHeaders } from "../../../ajaxCaller";
import { withTranslation } from 'react-i18next';

class HandlerEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dogClasses: null,
      breeds: null,
    };
  }

  getDogClasses = () => {
    ajaxCaller("DogPublic/GetDogClasses", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        dogClasses: response
      });
    });
  };

  getHandlerEventBreeds = () => {
    ajaxCaller("HandlerPublic/GetHandlerEventBreeds/" + this.props.event.id, {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        breeds: response
      });
    });
  };

  componentDidMount() {
    this.getDogClasses();
    this.getHandlerEventBreeds();
  }

  render() {
    const { t } = this.props;
    const { event } = this.props;
    const { breeds } = this.state;

    return (
      <div className="mt-3">
        {breeds && breeds.map(breed => (<table class="table table-striped ">
          <thead class="bg-primary">
            <tr class="table-primary">
              <th scope="col">{t("Дата")}</th>
              <th scope="col">{t("Навание выставки")}</th>
              <th scope="col">{t("Порода")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{event.start.toLocaleDateString()}</td>
              <td>{event.title}</td>
              <td>{breed.name}</td>
            </tr>
            <tr>
              <td colSpan="3" class="defaultCursor">
                {this.state.dogClasses && (<HandlerEventDetails key={breed.additionalId} dogClasses={this.state.dogClasses} breedId={breed.id} handlerEventBreedId={breed.additionalId}></HandlerEventDetails>)}
              </td>
            </tr>
          </tbody>
        </table>))}
      </div>
    );
  }
}

export default withTranslation()(HandlerEvent)