import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./handlers.css";
import { ajaxCaller } from "../../ajaxCaller";
import { handleInputChange } from "../../inputUtilities";
import { getCulture } from "../../localizer";
import HandlerEvent from "./handlerEvent";
import NonAuthComponent from "../../nonAuthComponent";
import { withTranslation } from 'react-i18next';

class HandlerCalendar extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: null,
      myEvents: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  getHandlerEvents = () => {
    ajaxCaller(
      "HandlerPublic/GetHandlerEventsByHandlerId/" + this.props.userId,
      {
        method: "get",
        headers: this.nonAuthHeaders
      }
    ).then(events => {
      this.setState({
        myEvents: events.map(event => ({
          id: event.id,
          title: event.eventName,
          allDay: true,
          start: new Date(event.date),
          end: new Date(event.date)
        }))
      });
    });
  };

  selectEvent = event => {
    this.setState({
      selectedEvent: event
    });
  };

  backToCalendar = () => {
    this.setState({
      selectedEvent: null
    });
  };

  componentDidMount() {
    this.getHandlerEvents();
  }

  render() {
    const { t } = this.props;
    
    const localizer = momentLocalizer(moment);

    return (
      <>
        <div
          id="handlerCalendar"
          style={{
            display: this.state.selectedEvent == null ? "block" : "none"
          }}
        >
          <Calendar
            selectable
            culture={getCulture()}
            localizer={localizer}
            events={this.state.myEvents}
            startAccessor="start"
            endAccessor="end"
            defaultView={Views.MONTH}
            onSelectSlot={this.handleSelect}
            onSelectEvent={event => this.selectEvent(event)}
            views={["month"]}
            messages={{
              previous: "<",
              next: ">",
              today: t("Сегодня")
            }}
          />
        </div>

        {this.state.selectedEvent && (
          <>
            <div id="handlerEventActions">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.backToCalendar}
              >
                {t("Назад к календарю")}
              </button>
            </div>
            <HandlerEvent event={this.state.selectedEvent}></HandlerEvent>
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(HandlerCalendar)