import { Component } from "react";
import {
  isAuthenticated,
  getCurrentUserId
} from "./authentification";
import { imageContainerUrl, dogPlaceHolder } from "./apiServer";
import { ajaxCaller, ajaxNonJsonCaller, nonAuthHeaders, authHeaders } from "./ajaxCaller";

export default class NonAuthComponent extends Component {
  currentUserId = null;

  nonAuthHeaders = null;

  authHeaders = null;

  apiLink = null;

  imageUrl = null;

  ajaxCall = null;

  ajaxNonJsonCall = null;

  constructor(props) {
    super(props);

    if (isAuthenticated()) {
        this.currentUserId = getCurrentUserId();
    }
    else {
        this.currentUserId = null;
    }    

    this.imageUrl = imageContainerUrl;

    this.ajaxCall = ajaxCaller;

    this.ajaxNonJsonCall = ajaxNonJsonCaller;

    this.dogPlaceHolder = dogPlaceHolder;

    this.userPlaceHolder = imageContainerUrl + "/common/userplaceholder.svg";

    this.nonAuthHeaders = nonAuthHeaders;

    this.authHeaders = authHeaders;
  }
}
