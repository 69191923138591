import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import BreedingAnnouncement from './breedingAnnouncement';

export default class BreedingAnnouncements extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = {
        dogBreedings: []
    };
  }  

  getBreedingAnnouncements = () => {
    this.ajaxCall("KennelPublic/GetBreedingAnnouncements/" + this.props.kennelId, {
        method: "get",
        headers: this.authHeaders
      }).then(dogBreedings => {
        this.setState({
            dogBreedings
        });
      });
  };  

  componentDidMount() {
    this.getBreedingAnnouncements();
  }

  render() {

    const {
        dogBreedings
    } = this.state;

    return (
      <div id="breedingAnnouncements">
        {dogBreedings && dogBreedings.map(dogBreeding => <BreedingAnnouncement key={"dogBreedingAnnouncement" + dogBreeding.id} dogBreeding={dogBreeding}></BreedingAnnouncement>)}
      </div>
    );
  }
}