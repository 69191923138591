export class PuppyStatus {
  static notForSale = "Не для продажи / Not for sale";

  static forSale = "Продается / For sale";

  static sold = "Продан / Sold";

  static reserved = "Забронирован / Reserved";

  static getStatus(status) {
    switch (status) {
      case 1:
        return PuppyStatus.notForSale;
      case 2:
        return PuppyStatus.forSale;
      case 3:
        return PuppyStatus.sold;
      case 4:
        return PuppyStatus.reserved;
      default:
        return "";
    }
  }
}

export class Gender {
    static male = "Кобель";
  
    static female = "Сука";
  
    static getGender(genderId) {
      switch (genderId) {
        case 1:
          return Gender.male;
        case 2:
          return Gender.female;
        default:
          return "";
      }
    }
  }