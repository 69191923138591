import React, { Component } from "react";
import Octicon, { ArrowRight } from "@primer/octicons-react";
import { withTranslation } from 'react-i18next';

class ArrowRightButton extends Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <span
          title={t("Отменить")}
          class="clickableIcon mr-2"
          onClick={this.props.onClick}
        >
          <Octicon icon={ArrowRight} size="large" />
        </span>
      </>
    );
  }
}

export default withTranslation()(ArrowRightButton)
