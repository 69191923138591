import React from "react";
import NonAuthComponent from "../../nonAuthComponent";

export default class FacebookPage extends NonAuthComponent {
  render() {
    return (
      <div style={{"textAlign": "center"}}>
        <div
          class="fb-page"
          data-href="https://www.facebook.com/TerraDogForYou/"
          data-tabs="timeline"
          data-width="500px"
          data-height="1000px"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/TerraDogForYou/"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/TerraDogForYou/">Terra-Dog</a>
          </blockquote>
        </div>
      </div>
    );
  }
}
