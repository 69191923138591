import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import { Link } from "react-router-dom";
import BreedingAnnouncements from './breedingAnnouncements';
import LitterAnnouncements from './litterAnnouncements';
import KennelNews from './kennelNews';
import "./kennels.css";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'
import PleaseRegisterButton from "../../components/pleaseRegisterButton";

class Kennel extends NonAuthComponent {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      kennelId: id,
      kennel: null,
      photos: [],
      dogs: [],
      genderId: 0
    };
  }

  getKennelPublicInfo = () => {
    if (this.state.dogId === 0) {
      return;
    }

    this.ajaxCall("KennelPublic/GetKennelPublicInfo/" + this.state.kennelId, {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        kennel: response,
        photo: response.photo
      });
    });
  };

  changeGenderInFilter = event => {
    this.setState({ genderId: parseInt(event.target.value) });
  };

  getKennelDogs = () => {
    this.ajaxCall("KennelPublic/GetKennelDogs/" + this.state.kennelId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogs: response
      });
    });
  };

  componentDidMount() {
    this.getKennelPublicInfo();
    this.getKennelDogs();
  }

  render() {
    const { t } = this.props;

    const { kennel, photo } = this.state;

    console.log(kennel);

    return (
      <div id="kennel" className="container">
        {this.currentUserId == null && (
          <PleaseRegisterButton text="Для общения с представителем питомника пожалуйста зарегистрируйтесь">PleaseRegisterButton</PleaseRegisterButton>
        )}

        {kennel && (
          <div className="kennelMainInfo row mb-3">
            <Helmet>
              <title>Питомник собак {kennel.name}</title>
              <meta name="description" content={`Питомник собак ${kennel.name} В нашем питомнике собак на портале TERRA-DOG имеются следующие породы ${kennel.breedsString} ${kennel.aboutKennel}`.replaceAll("null", "")}></meta>
            </Helmet>
            <div className="col-sm-3">
              {" "}
              <img
                src={
                  this.imageUrl +
                  "userphotos/" +
                  photo +
                  "?" +
                  this.state.imageHash
                }
                onError={e =>
                  (e.target.src = this.imageUrl + "common/userplaceholder.svg")
                }
                class="center-block card-img"
                alt={"Питомник собак" + kennel.name}
              ></img>
            </div>
            <div className="col-sm-9">
              <div class="one">
                <h1>Питомник собак {kennel.name}</h1>
              </div>              
              <table class="table table-striped">
                <tbody>
                  {(kennel.webSiteUrl && <tr>
                    <td>{t("Сайт питомника")}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={kennel.webSiteUrl}>{kennel.webSiteUrl}</a></td>
                  </tr>)}
                  <tr>
                    <td>{t("Породы")}</td>
                    <td>{kennel.breedsString}</td>
                  </tr>
                  <tr>
                    <td>{t("О питомнике")}</td>
                    <td>{kennel.aboutKennel}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h3 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {t("Собаки питомника")}
                </button>
              </h3>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="dogsContainer">
                  <div class="form-group col-sm-2 mt-3 pl-0">
                    <select
                      class="form-control"
                      id="genderId"
                      name="genderId"
                      value={this.state.genderId}
                      onChange={this.changeGenderInFilter}
                    >
                      <option value="0">{t("Все собаки")}</option>
                      <option value="1">{t("Кобели")}</option>
                      <option value="2">{t("Суки")}</option>
                    </select>
                  </div>

                  {this.state.dogs &&
                    this.state.dogs
                      .filter(
                        a =>
                          this.state.genderId === 0 ||
                          a.genderId === this.state.genderId
                      )
                      .map(dog => (
                        <Link to={"/dog/" + dog.id}>
                          <div key={dog.id} to={"/dog/" + dog.id}>
                            <div
                              className="card customCard mr-3 mb-3"
                              key={dog.id}
                            >
                              <img
                                src={this.imageUrl + "dogphotos/" + dog.photo}
                                className="card-img"
                                alt={dog.breed + " " + dog.dogName}
                                onError={e =>
                                  (e.target.src = this.dogPlaceHolder)
                                }
                              ></img>
                              <div className="card-body">
                                <h5 className="card-title">
                                  <Link
                                    className={"nav-link"}
                                    to={"/dog/" + dog.id}
                                  >
                                    {dog.dogName}
                                  </Link>
                                </h5>
                                <p className="card-text mb-0 pb-0">
                                  {dog.breed}
                                </p>
                                {dog.birthDate && (
                                  <p className="card-text">
                                    <small className="text-muted">
                                      {t("Дата рождения")}: {dog.birthDate}
                                    </small>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="headingTwo">
              <h3 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {t("Анонсы вязок")}
                </button>
              </h3>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <BreedingAnnouncements kennelId={this.state.kennelId}></BreedingAnnouncements>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="headingThree">
              <h3 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {t("Щенки")}
                </button>
              </h3>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <LitterAnnouncements kennelId={this.state.kennelId}></LitterAnnouncements>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="headingNews">
              <h3 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseNews"
                  aria-expanded="false"
                  aria-controls="collapseNews"
                >
                  {t("Новости питомника")}
                </button>
              </h3>
            </div>
            <div
              id="collapseNews"
              class="collapse"
              aria-labelledby="headingNews"
              data-parent="#accordion"
            >
              <KennelNews kennelId={this.state.kennelId}></KennelNews>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Kennel)