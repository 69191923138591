import React, { Component } from "react";
import Octicon, { Check } from "@primer/octicons-react";
import { withTranslation } from 'react-i18next';

class SaveButton extends Component {
  render() {
    const { t } = this.props;

    const disabled = this.props.disabled != null && this.props.disabled;

    return (
      <>
        <span
          title={t("Сохранить")}
          class={"clickableIcon mr-2" + (disabled ? " disabledIcon" : "")}
          onClick={disabled ? () => {} : this.props.onClick}
        >
          <Octicon icon={Check} size="medium" />
        </span>
      </>
    );
  }
}

export default withTranslation()(SaveButton)