import React from "react";
import AuthComponent from "../../../authComponent";
import Litter from "./litter";
import EditButton from "../../../components/editButton";
import { withTranslation } from 'react-i18next';

class Puppies extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      litters: [],
      selectedLitter: null,
      addEditLitter: false
    };
  }

  getLitters = () => {
    this.ajaxCall("Dog/GetLitters/" + this.props.dogId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        litters: response
      });
    });
  };

  refreshLitters = () => {
    this.getLitters();
  };

  addEditLitters = litter => {
    this.setState({
      addEditLitter: true,
      selectedLitter: litter
    });
  };

  cancelEditingLitter = () => {
    this.setState({
      selectedLitter: null,
      addEditLitter: false
    });
  };

  componentDidMount() {
    this.getLitters();
  }

  render() {
    const { t } = this.props;

    const { litters, selectedLitter, addEditLitter } = this.state;

    return addEditLitter === false ? (
      <div>
        <div id="litters">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">{t("Дата рождения")}</th>
                <th scope="col">{t("Пара")}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {litters &&
                litters.map(d => (
                  <tr key={d.id} onClick={() => this.addEditLitters(d)}>
                    <td>{d.puppiesBirthDateString}</td>
                    <td>
                      {d.firstDogName} & {d.secondDogName}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <EditButton></EditButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={() => this.addEditLitters(null)}
          className="btn btn-primary mt-3"
        >
          {t("Добавить помет")}
        </button>
      </div>
    ) : (
      <div>
        <button
          type="button"
          onClick={this.cancelEditingLitter}
          className="btn btn-secondary mb-3"
        >
          {t("Вернуться в раздел \"Пометы\"")}
        </button>

        <Litter
          litter={selectedLitter}
          refreshLitters={this.refreshLitters}
          dogId={this.props.dogId}
          cancelEditingLitter={this.cancelEditingLitter}
          hasKennel={this.props.hasKennel}
        ></Litter>
      </div>
    );
  }
}


export default withTranslation()(Puppies)