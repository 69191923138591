import React, { Component } from "react";
import Octicon, { Trashcan } from "@primer/octicons-react";
import DeletionDialog from "./deletionDialog";
import { withTranslation } from 'react-i18next';

class DeleteButton extends Component {
  render() {
    const { t, entity, id, onClick, confirmationText, asIcon, label } = this.props;

    const uniqueDialogId = entity + "_" + id;
    return (
      <>
        <DeletionDialog
          key={"DeletionDialog" + uniqueDialogId}
          id={uniqueDialogId}
          onClick={onClick}
          confirmationText={confirmationText}
        ></DeletionDialog>
        {asIcon === false ? (
          <button
            key={"DeletionDialogButton" + uniqueDialogId}
            type="button"
            data-toggle="modal"
            className="btn btn-danger mt-3"
            data-target={"#" + uniqueDialogId}
          >
            {label == null ? t("Удалить") : label}
          </button>
        ) : (
          <span
            key={"DeletionDialogIcon" + uniqueDialogId}
            title={t("Удалить")}
            class="clickableIcon trashIcon mr-2"
            data-toggle="modal"
            data-target={"#" + uniqueDialogId}
          >
            <Octicon icon={Trashcan} size="medium" />
          </span>
        )}
      </>
    );
  }
}

export default withTranslation()(DeleteButton)
