import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import ArrowLeftButton from "../../components/arrowLeftButton";
import ArrowRightButton from "../../components/arrowRightButton";
import { Gender, PuppyStatus } from "../../enums";
import { withTranslation } from 'react-i18next';

class LitterPuppy extends NonAuthComponent {
  render() {
    const { puppy, t } = this.props;

    return (
      <div className="litterPuppy mb-5 mt-5">
        <div
          id={"carousel" + puppy.id}
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            {puppy.photos &&
              puppy.photos.length > 0 &&
              puppy.photos.map(photo => (
                <li
                  data-target={"#carousel" + puppy.id}
                  data-slide-to={puppy.photos.indexOf(photo)}
                  class={puppy.photos.indexOf(photo) === 0 ? "active" : ""}
                ></li>
              ))}
          </ol>
          <div class="carousel-inner">
            {puppy.photos &&
              puppy.photos.length > 0 &&
              puppy.photos.map(photo => (
                <div
                  class={
                    "carousel-item" +
                    (puppy.photos.indexOf(photo) === 0 ? " active" : "")
                  }
                >
                  <img
                    src={this.imageUrl + "puppyphotos/" + photo}
                    class="center-block"
                    alt="Щенок питомника"
                  ></img>
                </div>
              ))}
          </div>

          {puppy.photos && puppy.photos.length > 1 && (
            <>
              <a
                class="carousel-control-prev"
                href={"#carousel" + puppy.id}
                role="button"
                data-slide="prev"
              >
                <ArrowLeftButton></ArrowLeftButton>
              </a>
              <a
                class="carousel-control-next"
                href={"#carousel" + puppy.id}
                role="button"
                data-slide="next"
              >
                <ArrowRightButton></ArrowRightButton>
              </a>
            </>
          )}
        </div>

        <table class="table table-striped mt-3">
          <colgroup>
            <col style={{ width: "30%" }}></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <b>{t("Имя")}</b>
              </td>
              <td>{puppy.name}</td>
            </tr>
            <tr>
              <td>{t("Пол")}</td>
              <td>{Gender.getGender(puppy.genderId)}</td>
            </tr>
            <tr>
              <td>{t("Статус")}</td>
              <td>{PuppyStatus.getStatus(puppy.status)}</td>
            </tr>
            <tr>
              <td>{t("Окраc")}</td>
              <td>{puppy.color}</td>
            </tr>
            <tr>
              <td>{t("Тип шерсти")}</td>
              <td>{puppy.typeOfCoat}</td>
            </tr>
            <tr>
              <td>{t("Цена")}</td>
              <td>{puppy.price}</td>
            </tr>
            <tr>
              <td>{t("О щенке")}</td>
              <td>{puppy.notes}</td>
            </tr>
          </tbody>
        </table>

        <hr></hr>
        <hr></hr>
        <hr></hr>
      </div>
    );
  }
}

export default withTranslation()(LitterPuppy)