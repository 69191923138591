import React from "react";
import TerraDatePicker from "../../components/terraDatePicker";
import SuccessfullMessage from "../../components/successfullMessage";
import AuthComponent from "../../authComponent";
import VetActions from "../myDogs/vetActions/vetActions";
import Breedings from "./breeding/breedings";
import Litters from './litters/litters';
import DogShowResults from "./dogShowResults";
import { handleInputChange } from "../../inputUtilities";
import Octicon, { FileSymlinkFile, Trashcan } from "@primer/octicons-react";
import PinButton from "../../components/pinButton";
import "./myDogs.css";
import Select from "react-select";
import { convertToDate } from "../../date";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class MyDog extends AuthComponent {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      dogId: id,
      breedId: null,
      name: "",
      genderId: null,
      kennelOrganizationId: null,
      birthDate: null,
      inMyKennel:true,
      dogPhotos: [],
      allBreeds: [],
      firstTimeLoading: true,
      savedSuccessfully: null,
      availableForBreeding: false,
      imageHash: 0,
      organizations: [],

      hasKennel: false
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.uploadDogPhoto = this.uploadDogPhoto.bind(this);
  }

  handleBreedChange = selectedBreed => {
    this.setState({ breedId: selectedBreed.id });
  };

  currentUserHasKennel = () => {
    this.ajaxCall("Kennel/CurrentUserHasKennel", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        hasKennel: response.currentUserHasKennel
      });
    });
  };

  getKennelOrganizations = () => {
    this.ajaxCall("DogPublic/GetKennelOrganizations", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        organizations: response
      });
    });
  };

  getAllBreeds = () => {
    this.ajaxCall("BreedPublic/GetAllBreeds", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        allBreeds: response
      });
    });
  };

  deletePhoto = photoName => {
    this.ajaxCall("Image/DeleteImage/dogphotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogPhotos: this.state.dogPhotos.filter(a => a !== response.imageName)
      });
    });
  };

  setPrimaryPhoto = photoName => {
    this.ajaxCall("Dog/SetPrimaryPhoto/" + this.state.dogId + "/" + photoName, {
      method: "post",
      headers: this.authHeaders
    });
  };

  rotatePhoto = photoName => {
    this.ajaxCall("Image/RotateImage/dogphotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(() => {
      const hashResult = new Date().getTime();
      this.setState({
        imageHash: hashResult
      });
    });
  };

  getDogGeneralInfo = () => {
    if (this.state.dogId === 0) {
      return;
    }

    this.ajaxCall("Dog/GetDogGeneralInfo/" + this.state.dogId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      console.log(response);

      if (response.dogGeneral == null) {
        return;
      }

      this.setState({
        dogId: response.dogGeneral.dogId,
        breedId: response.dogGeneral.breedId,
        name: response.dogGeneral.name,
        genderId: response.dogGeneral.genderId,
        kennelOrganizationId: response.dogGeneral.kennelOrganizationId,
        organizations: response.dogGeneral.organizations,
        availableForBreeding: response.dogGeneral.availableForBreeding,
        inMyKennel: response.dogGeneral.inMyKennel,
        birthDate: convertToDate(response.dogGeneral.birthDate),
        dogPhotos: response.dogGeneral.dogPhotos,
        mother: response.dogOrigin.mother,
        father: response.dogOrigin.father,
        breeder: response.dogOrigin.breeder,
        kennel: response.dogOrigin.kennel,
        pedigreeDatabaseLink: response.dogOrigin.pedigreeDatabaseLink,
        training: response.dogTitlesAndTests.training,
        titles: response.dogTitlesAndTests.titles,
        tests: response.dogTitlesAndTests.tests
      });
    });
  };

  deleteDog = () => {
    if (this.state.dogId != null && this.state.dogId > 0) {
      this.ajaxCall("Dog/DeleteDog/" + this.state.dogId, {
        method: "post",
        headers: this.authHeaders
      }).then(response => {
        window.location.href = "/myDogs";
      });
    }
  };

  saveDogGeneralInfo = () => {
    const bodyJson = {
      dogId: this.state.dogId,
      dogPhotos: this.state.dogPhotos,
      breedId: this.state.breedId,
      name: this.state.name,
      genderId: this.state.genderId,
      kennelOrganizationId: this.state.kennelOrganizationId,
      availableForBreeding: this.state.availableForBreeding,
      inMyKennel: this.state.inMyKennel,
      birthDate:
        this.state.birthDate == null
          ? null
          : this.state.birthDate.toLocaleDateString('ru-RU')
    };

    console.log('BODY SaveDogGeneralInfo:');
    console.log(bodyJson);

    this.ajaxCall("Dog/SaveDogGeneralInfo", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      if (!response.isError) {
        setTimeout(() => {
          this.setState({ savedSuccessfully: null });
        }, 2000);
      }

      // if (response.dogId !== this.state.dogId) {
      //   window.location.href = "/myDog/" + response.dogId;
      // }

      this.setState({
        savedSuccessfully: !response.isError,
        dogId: response.dogId
      });
    });
  };

  saveDogOriginInfo = () => {
    const bodyJson = {
      dogId: this.state.dogId,
      mother: this.state.mother,
      father: this.state.father,
      breeder: this.state.breeder,
      kennel: this.state.kennel,
      pedigreeDatabaseLink: this.state.pedigreeDatabaseLink
    };

    this.ajaxCall("Dog/SaveDogOriginInfo", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      if (!response.isError) {
        setTimeout(() => {
          this.setState({ savedSuccessfully: null });
        }, 2000);
      }

      this.setState({
        savedSuccessfully: !response.isError,
        dogId: response.dogId
      });
    });
  };

  saveTitlesAndTestsInfo = () => {
    const bodyJson = {
      dogId: this.state.dogId,
      training: this.state.training,
      titles: this.state.titles,
      tests: this.state.tests
    };

    this.ajaxCall("Dog/SaveTitlesAndTestsInfo", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      if (!response.isError) {
        setTimeout(() => {
          this.setState({ savedSuccessfully: null });
        }, 2000);
      }

      this.setState({
        savedSuccessfully: !response.isError,
        dogId: response.dogId
      });
    });
  };

  handleCalendarChange = date => {
    this.setState({
      birthDate: date
    });
  };

  uploadDogPhoto(event) {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    var formData = new FormData();
    formData.append(`photo`, file);

    this.ajaxCall(
      "Image/UploadImage/DogPhotos/" +
        (this.state.dogId == null ? "0" : this.state.dogId),
      {
        method: "post",
        headers: this.authHeadersForFiles,
        body: formData
      }
    ).then(response => {
      const dogPhotos = this.state.dogPhotos;
      dogPhotos.push(response.fileName);

      this.setState({
        dogPhotos: dogPhotos
      });
    });
  }

  componentDidMount() {
    if (this.state.firstTimeLoading) {
      this.getAllBreeds();
      this.getDogGeneralInfo();
      
      if (this.state.dogId === 0 || this.state.dogId === '0') {
        this.getKennelOrganizations();
      }

      this.currentUserHasKennel();

      this.setState({ firstTimeLoading: false });
    }
  }

  render() {
    const { t } = this.props;

    const dogId = this.state.dogId;
    const isDisabled = dogId != null && dogId > 0 ? "" : " disabled";
    const saveDogGeneralInfoDisabled = this.state.name == null || this.state.name === '' || this.state.genderId == null || this.state.genderId === '';

    return (
      <div id="myDog" class="container">
        <div class="one">
          <h1>{this.state.name ? this.state.name : "Добавление Вашей собаки на сайт..."}</h1>
        </div>    

        <Helmet>
          <title>{this.state.name ? this.state.name : "Добавление Вашей собаки на сайт..."}</title>
          <meta name="description" content={this.state.name ? this.state.name : "Добавление Вашей собаки на сайт..."}></meta>
        </Helmet>
        <div
          class="modal fade"
          id="confirmDeletion"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {t("Вы уверены что хотите удалить данную анкету?")}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.deleteDog}
                >
                  {t("Удалить")}
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-secondary"
                >
                  {t("Отмена")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="main-info-tab"
              data-toggle="pill"
              href="#main-info"
              role="tab"
              aria-selected="true"
            >
              {t("Основное")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="origin-tab"
              data-toggle="pill"
              href="#origin"
              role="tab"
              aria-selected="false"
            >
              {t("Происхождение")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="titles-and-tests-tab"
              data-toggle="pill"
              href="#titles-and-tests"
              role="tab"
              aria-selected="false"
            >
              {t("Титулы и тесты")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="health-tab"
              data-toggle="pill"
              href="#health"
              role="tab"
              aria-selected="false"
            >
              {t("Здоровье")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="dogShowResult-tab"
              data-toggle="pill"
              href="#dogShowResult"
              role="tab"
              aria-selected="false"
            >
              {t("Результаты выставок")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="breedingStatistic-tab"
              data-toggle="pill"
              href="#breedingStatistic"
              role="tab"
              aria-selected="false"
            >
              {t("Статистика вязок")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="puppies-tab"
              data-toggle="pill"
              href="#puppies"
              role="tab"
              aria-selected="false"
            >
              {t("Пометы")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class={"nav-link" + isDisabled}
              id="actions-tab"
              href="#deleteDog"
              role="tab"
              aria-selected="false"
              data-toggle="modal"
              data-target="#confirmDeletion"
            >
              {t("Удалить анкету")}
            </a>
          </li>
        </ul>
        <div class="tab-content" id="tab-content">
          {this.state.savedSuccessfully === true && (
            <SuccessfullMessage
              showMessage={this.state.savedSuccessfully}
            ></SuccessfullMessage>
          )}

          <div
            class="tab-pane fade show active"
            id="main-info"
            role="tabpanel"
            aria-labelledby="main-info-tab"
          >
            <form class="row">
              <div class="col-sm-4">
                {this.state.dogPhotos &&
                  this.state.dogPhotos.map((dogPhoto, idx) => (
                    <div class="photoContainer">
                      <div class="photoActions">
                        <PinButton
                          title="Сделать главным фото"
                          onClick={() => this.setPrimaryPhoto(dogPhoto)}
                        ></PinButton>
                        <span
                          title="Перевернуть фото"
                          class="clickableIcon mr-2"
                          onClick={() => this.rotatePhoto(dogPhoto)}
                        >
                          <Octicon icon={FileSymlinkFile} size="medium" />
                        </span>
                        <span
                          title="Удалить фото"
                          class="clickableIcon mr-2"
                          onClick={() => this.deletePhoto(dogPhoto)}
                        >
                          <Octicon icon={Trashcan} size="medium" />
                        </span>
                      </div>
                      <img
                        src={
                          this.imageUrl +
                          "dogphotos/" +
                          dogPhoto +
                          "?" +
                          this.state.imageHash
                        }
                        class="card-img mb-3"
                        alt=""
                      ></img>
                    </div>
                  ))}

                {(this.state.dogPhotos == null ||
                  this.state.dogPhotos.length === 0) && (
                  <img
                    src={this.dogPlaceHolder}
                    class="card-img mb-3 cart-img-top-placeholder"
                    alt=""
                  ></img>
                )}

                <div class="form-group">
                  {(this.state.dogPhotos == null ||
                    this.state.dogPhotos.length < 7) && (
                    <>
                      <button
                        type="button"
                        class="btn btn-primary uploadButton"
                      >
                        <label for="dogPhoto">{t("Добавить фото")}</label>
                      </button>
                      <input
                        type="file"
                        class="form-control-file"
                        id="dogPhoto"
                        style={{ visibility: "hidden" }}
                        onChange={this.uploadDogPhoto}
                      ></input>
                    </>
                  )}
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="breedId">{t("Порода")}</label>
                  <Select
                    value={this.state.allBreeds.filter(
                      option => option.id === this.state.breedId
                    )}
                    onChange={this.handleBreedChange}
                    options={this.state.allBreeds}
                  />
                </div>
                <div class="form-group">
                  <label for="name">{t("Кличка")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    autocomplete="off"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="genderId">{t("Пол")}</label>
                  <select
                    class="form-control"
                    id="genderId"
                    name="genderId"
                    value={this.state.genderId}
                    onChange={this.handleInputChange}
                  >
                    <option value=""></option>
                    <option value="1">{t("Кобель")}</option>
                    <option value="2">{t("Сука")}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="kennelOrganizationId">{t("Документы")}</label>
                  <select
                    class="form-control"
                    id="kennelOrganizationId"
                    name="kennelOrganizationId"
                    value={this.state.kennelOrganizationId}
                    onChange={this.handleInputChange}
                  >
                    <option value=""></option>
                    {this.state.organizations &&
                      this.state.organizations.map(a => (
                        <option value={a.id}>{a.name}</option>
                      ))}
                  </select>
                </div>
                {(this.state.genderId === "1" || this.state.genderId === 1) && this.state.kennelOrganizationId && (
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      autocomplete="off"
                      id="availableForBreeding"
                      name="availableForBreeding"
                      onChange={this.handleInputChange}
                      checked={this.state.availableForBreeding}
                    ></input>
                    <label class="form-check-label" for="accepted">
                      {t("Доступен для вязок (Отображать кобеля в разделе \"Вязки\")")}
                    </label>
                  </div>
                )}
                <div class="form-group">
                  <label for="genderId" className="mr-2 blockElement">
                    {t("Дата рождения")}
                  </label>
                  <TerraDatePicker
                    selected={this.state.birthDate}
                    onChange={this.handleCalendarChange}
                    className="form-control"
                  />
                </div>

                {this.state.hasKennel && (
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      autocomplete="off"
                      id="inMyKennel"
                      name="inMyKennel"
                      onChange={this.handleInputChange}
                      checked={this.state.inMyKennel}
                    ></input>
                    <label class="form-check-label" for="inMyKennel">
                      {t("Отображать в разделе моего питомника")}
                    </label>
                  </div>
                )}

                <button
                  type="button"
                  onClick={this.saveDogGeneralInfo}
                  disabled={saveDogGeneralInfoDisabled}
                  class="btn btn-primary"
                >
                  {t("Сохранить")}
                </button>
              </div>
            </form>
          </div>
          <div
            class="tab-pane fade"
            id="origin"
            role="tabpanel"
            aria-labelledby="origin-tab"
          >
            <form action="http://localhost:3000/cabinet" class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="mother">{t("Мать")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="mother"
                    name="mother"
                    autocomplete="off"
                    value={this.state.mother}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="father">{t("Отец")}</label>
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    id="father"
                    name="father"
                    value={this.state.father}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="breeder">{t("Заводчик")}</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="breeder"
                    name="breeder"
                    value={this.state.breeder}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="kennel">{t("Питомник")}</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="kennel"
                    name="kennel"
                    value={this.state.kennel}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="pedigreeDatabaseLink">
                    {t("Ссылка на родословную")}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="pedigreeDatabaseLink"
                    name="pedigreeDatabaseLink"
                    value={this.state.pedigreeDatabaseLink}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <button
                  type="button"
                  onClick={this.saveDogOriginInfo}
                  class="btn btn-primary"
                >
                  {t("Сохранить")}
                </button>
              </div>
            </form>
          </div>
          <div
            class="tab-pane fade"
            id="titles-and-tests"
            role="tabpanel"
            aria-labelledby="titles-and-tests-tab"
          >
            <form action="http://localhost:3000/cabinet" class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="training">{t("Дрессировка")}</label>
                  <textarea
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    id="training"
                    name="training"
                    rows="3"
                    value={this.state.training}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="titles">{t("Выставочные титулы")}</label>
                  <textarea
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="titles"
                    name="titles"
                    rows="3"
                    value={this.state.titles}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="tests">{t("Тесты на породные заболевания")}</label>
                  <textarea
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    id="tests"
                    name="tests"
                    rows="3"
                    value={this.state.tests}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>

                <button
                  type="button"
                  onClick={this.saveTitlesAndTestsInfo}
                  class="btn btn-primary"
                >
                  {t("Сохранить")}
                </button>
              </div>
            </form>
          </div>
          <div
            class="tab-pane fade"
            id="health"
            role="tabpanel"
            aria-labelledby="health-tab"
          >
            <VetActions dogId={dogId}></VetActions>
          </div>
          <div
            class="tab-pane fade"
            id="dogShowResult"
            role="tabpanel"
            aria-labelledby="dogShowResult-tab"
          >
            <DogShowResults dogId={dogId}></DogShowResults>
          </div>
          <div
            class="tab-pane fade"
            id="breedingStatistic"
            role="tabpanel"
            aria-labelledby="breedingStatistic-tab"
          >
            <Breedings
              dogId={dogId}
              isMale={this.state.genderId === 1}
              hasKennel={this.state.hasKennel}
            ></Breedings>
          </div>
          <div
            class="tab-pane fade"
            id="puppies"
            role="tabpanel"
            aria-labelledby="puppies-tab"
          >
            <Litters
              dogId={dogId}
              isMale={this.state.genderId === 1}
              hasKennel={this.state.hasKennel}
            ></Litters>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyDog)