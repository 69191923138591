import React from "react";
import AuthComponent from "../../authComponent";
import "../dogs/dogs.css";
import DogCard from "../../components/dogCard";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class FavoriteDogs extends AuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      dogs: []
    };
  }

  getDogs = () => {
    this.ajaxCall("FavoriteDog/GetFavoriteDogs", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogs: response
      });
    });
  };

  componentDidMount() {
    this.getDogs();
  }

  removedFromTheFavorite = dogId => {
    this.setState({
      dogs: this.state.dogs.map(dog => {
        if (dog.id === dogId) {
          dog.inFavorite = false;
        }
        return dog;
      })
    });
  };

  render() {
    return (
      <div id="dogs" className="container">
        <div class="one">
          <h1>Собаки добавленные в Избранное</h1>
        </div>    

        <Helmet>
          <title>Собаки добавленные в Избранное</title>
          <meta name="description" content="Собаки добавленные в избранное. В этом списке собаки которые Вам больше всего понравились на нашем портале TERRA-DOG"></meta>
        </Helmet>
        <div className="dogsContainer">
          {this.state.dogs &&
            this.state.dogs
              .filter(a => a.inFavorite)
              .map(dog => (
                <DogCard
                  dog={dog}
                  removedFromTheFavorite={() =>
                    this.removedFromTheFavorite(dog.id)
                  }
                ></DogCard>
              ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(FavoriteDogs)