import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import "./dogs.css";
import Select from "react-select";
import DogCard from "../../components/dogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class Dogs extends NonAuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      dogs: [],
      organizations: [],
      allBreeds: [],
      genderId: null,
      kennelOrganizationId: null,
      breedId: null
    };
  }

  handleBreedChange = selectedBreed => {
    this.setState({ breedId: selectedBreed.id });
    this.getDogs({ breedId: selectedBreed.id }, false);
  };

  changeKennelInFilter = event => {
    this.setState({ kennelOrganizationId: event.target.value });
    this.getDogs({ kennelOrganizationId: event.target.value }, false);
  };

  changeGenderInFilter = event => {
    this.setState({ genderId: event.target.value });
    this.getDogs({ genderId: event.target.value }, false);
  };

  getDogs = (filter, byPager) => {
    const bodyJson = {
      genderId:
        filter != null && filter.genderId != null
          ? filter.genderId
          : this.state.genderId,
      kennelOrganizationId:
        filter != null && filter.kennelOrganizationId != null
          ? filter.kennelOrganizationId
          : this.state.kennelOrganizationId,
      breedId:
        filter != null && filter.breedId != null
          ? filter.breedId
          : this.state.breedId,
      onlyDogsAvailableForBreeding: this.props.onlyDogsAvailableForBreeding,
      skip: byPager === true ? this.state.dogs.length : 0,
      take: 15
    };

    this.ajaxCall("DogPublic/GetDogs", {
      method: "post",
      headers: this.nonAuthHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      this.setState({
        dogs: byPager === true ? this.state.dogs.concat(response) : response
      });
    });
  };

  getKennelOrganizations = () => {
    this.ajaxCall("DogPublic/GetKennelOrganizations", {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        organizations: response
      });
    });
  };

  getAllBreeds = () => {
    this.ajaxCall("BreedPublic/GetAllBreeds", {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      response.unshift({
        id: "",
        label: this.props.t("Все породы"),
        name: null,
        value: ""
      });
      this.setState({
        allBreeds: response
      });
    });
  };

  componentDidMount() {
    this.getDogs(null, false);
    this.getKennelOrganizations();
    this.getAllBreeds();
  }

  render() {
    const { t } = this.props;

    return (
      <div id="dogs" className="container">
        {this.props.onlyDogsAvailableForBreeding ? (
          <Helmet>
          <title>Кобель для племенного использования</title>
          <meta name="description" content="Одни из лучших на наш взгляд Питомники собак предлагающие услуги вязки / кобелей для племенного использования"></meta>
          </Helmet>
        ) : (
          <Helmet>
          <title>Собаки TERRA-DOG</title>
          <meta name="description" content="Одни из лучших на наш взгляд Питомники собак предлагающие услуги вязки / кобелей для племенного использования"></meta>
          </Helmet>
        )}
        {this.props.onlyDogsAvailableForBreeding ? (
          <div class="one"><h1>Кобель для племенного использования</h1></div>
        ) : (
          <div class="one"><h1>Собаки TERRA-DOG</h1></div>
        )}
        <div id="filters" class="container pl-0 row">
          <div id="breedFilterContainer" class="form-group col-sm-6">
            <label for="breedId">{t("Порода")}</label>
            <Select
              value={this.state.allBreeds.filter(
                option => option.id === this.state.breedId
              )}
              onChange={this.handleBreedChange}
              options={this.state.allBreeds}
            />
          </div>

          <div class="form-group col-sm-2">
            <label for="genderId">{t("Документы")}</label>
            <select
              class="form-control"
              id="kennelOrganizationId"
              name="kennelOrganizationId"
              value={this.state.kennelOrganizationId}
              onChange={this.changeKennelInFilter}
            >
              <option value="">{t("Все")}</option>
              {this.state.organizations &&
                this.state.organizations.map(a => (
                  <option value={a.id}>{a.name}</option>
                ))}
            </select>
          </div>

          {!this.props.onlyDogsAvailableForBreeding && (
            <div class="form-group col-sm-2">
              <label for="genderId">{t("Пол")}</label>
              <select
                class="form-control"
                id="genderId"
                name="genderId"
                value={this.state.genderId}
                onChange={this.changeGenderInFilter}
              >
                <option value="">{t("Все")}</option>
                <option value="1">{t("Кобель")}</option>
                <option value="2">{t("Сука")}</option>
              </select>
            </div>
          )}
        </div>
        <div className="dogsContainer">
          <InfiniteScroll
            dataLength={this.state.dogs.length}
            next={() => this.getDogs(null, true)}
            hasMore={false}
          >
            {this.state.dogs &&
              this.state.dogs.map(dog => <DogCard dog={dog}></DogCard>)}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Dogs)