import React from "react";
import AuthComponent from "../../authComponent";
import { handleInputChange } from "../../inputUtilities";
import TerraDatePicker from "../../components/terraDatePicker";
import DeleteButton from "../../components/deleteButton";
import EditButton from "../../components/editButton";
import CancelButton from "../../components/cancelButton";
import SaveButton from "../../components/saveButton";
import { convertToDate } from '../../date';
import { withTranslation } from 'react-i18next';

class DogShowResults extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      id: 0,
      newStarted: false,
      showDate: null,
      city: "",
      showName: "",
      expert: "",
      catalogNo: null,
      dogClassId: null,
      result: null,
      allowToShowDogShowResults: false,
      dogClasses: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  handleCalendarChangeShowDate = date => {
    this.setState({
      showDate: date
    });
  };

  handleCatalogNoChange = e => {
    if (e.target.value !== "") {
      this.setState({ catalogNo: e.target.value.replace(/\D/g, "") });
    } else {
      this.setState({ catalogNo: "" });
    }
  };

  allowToShowChange = () => {
    this.ajaxCall("Dog/SaveFlagAllowToShowDogShowResults/" + this.props.dogId, {
      method: "post",
      headers: this.authHeaders,
      body: !this.state.allowToShowDogShowResults
    });

    this.setState({
      allowToShowDogShowResults: !this.state.allowToShowDogShowResults
    });
  };

  getData = () => {
    this.ajaxCall("Dog/GetDogShowResults/" + this.props.dogId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {

      this.setState({
        data: response.data,
        allowToShowDogShowResults: response.allowToShowDogShowResults
      });
    });
  };

  getDogClasses = () => {
    this.ajaxCall("DogPublic/GetDogClasses", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogClasses: response
      });
    });
  };

  addData = () => {
    this.setState({
      newStarted: true,
      data: this.state.data.concat({
        id: 0,
        newStarted: true,
        isNew: true,
        showDate: null,
        city: "",
        showName: "",
        expert: "",
        catalogNo: null,
        dogClassId: null,
        result: null
      })
    });
  };

  cancelEdit = () => {
    let data = this.state.data;
    if (this.state.id > 0) {
      data = data.map(a => {
        if (a.id === this.state.id) {
          a.editMode = false;
        }
        return a;
      });
    } else {
      data.splice(data.length - 1, 1);
    }

    this.setState({
      data,
      id: 0,
      newStarted: false,
      isNew: false,
      showDate: null,
      city: "",
      showName: "",
      expert: "",
      dogClasId: null,
      catalogNo: null,
      result: null
    });
  };

  saveData = () => {
    const bodyJson = {
      id: this.state.id,
      dogId: this.props.dogId,
      showDate: this.state.showDate.toLocaleDateString('en-US'),
      city: this.state.city,
      showName: this.state.showName,
      expert: this.state.expert,
      catalogNo: this.state.catalogNo,
      dogClassId: this.state.dogClassId,
      result: this.state.result
    };

    const newData = this.state.id === 0;

    this.ajaxCall("Dog/SaveDogShowResult", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      let data = this.state.data;
      if (newData) {
        data.splice(data.length - 1, 1);
        data = data.concat(response.entity);
      } else {
        data = data.map(a => {
          if (a.id === response.entity.id) {
            return response.entity;
          } else {
            return a;
          }
        });
      }

      this.setState({
        data,
        id: 0,
        newStarted: false,
        showDate: null,
        city: "",
        showName: "",
        expert: "",
        catalogNo: null,
        dogClassId: null,
        result: null
      });
    });
  };

  editEntity = id => {
    let showDate = null;
    let city = "";
    let showName = "";
    let expert = "";
    let catalogNo = null;
    let dogClassId = null;
    let result = "";
    let identifier = 0;

    this.setState({
      newStarted: true,
      data: this.state.data
        .filter(a => !a.isNew)
        .map(a => {
          if (a.id === id) {
            a.editMode = true;
            showDate = convertToDate(a.showDate);
            city = a.city;
            showName = a.showName;
            expert = a.expert;
            catalogNo = a.catalogNo;
            dogClassId = a.dogClassId;
            result = a.result;
            identifier = a.id;
          } else {
            a.editMode = false;
          }
          return a;
        }),
      showDate,
      city,
      showName,
      expert,
      catalogNo,
      dogClassId,
      result,
      id: identifier
    });
  };

  deleteEntity = id => {
    this.ajaxCall("Dog/DeleteDogShowResult", {
      method: "post",
      headers: this.authHeaders,
      body: id
    }).then(response => {
      this.setState({
        data: this.state.data.filter(a => a.id !== id)
      });
    });
  };

  componentDidMount() {
    this.getData();
    this.getDogClasses();
  }

  render() {
    const { t } = this.props;

    const data = this.state.data;
    const saveButtonDisabled =
      this.state.showDate == null ||
      this.state.city == null ||
      this.state.city === "" ||
      this.state.showName == null ||
      this.state.showName === "";

    return (
      <div id="healthTable">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t("Дата")}</th>
              <th scope="col">{t("Город")}</th>
              <th scope="col">{t("Название")}</th>
              <th scope="col">{t("Эксперт")}</th>
              <th scope="col" title={t("№ по каталогу")}>
                №
              </th>
              <th scope="col">{t("Класс")}</th>
              <th scope="col">{t("Результат")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map != null &&
              data.map(t =>
                t.isNew || t.editMode ? (
                  <tr key="0">
                    <td>
                      <TerraDatePicker
                        selected={this.state.showDate}
                        onChange={this.handleCalendarChangeShowDate}
                        id="showDate"
                        name="showDate"
                        key="showDateCalendar"
                        className="form-control"
                        autocomplete="off"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="city"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleInputChange}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="showName"
                        name="showName"
                        value={this.state.showName}
                        onChange={this.handleInputChange}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="expert"
                        name="expert"
                        value={this.state.expert}
                        onChange={this.handleInputChange}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="catalogNo"
                        name="catalogNo"
                        value={this.state.catalogNo}
                        onChange={e => this.handleCatalogNoChange(e)}
                      ></input>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        id="dogClassId"
                        name="dogClassId"
                        value={this.state.dogClassId}
                        onChange={this.handleInputChange}
                      >
                        <option value=""></option>
                        {this.state.dogClasses &&
                          this.state.dogClasses.map(a => (
                            <option value={a.id}>{a.name}</option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="result"
                        name="result"
                        value={this.state.result}
                        onChange={this.handleInputChange}
                      ></input>
                    </td>
                    <td className="text-right">
                      <SaveButton
                        onClick={this.saveData}
                        disabled={saveButtonDisabled}
                      ></SaveButton>
                      <CancelButton onClick={this.cancelEdit}></CancelButton>
                    </td>
                  </tr>
                ) : (
                  <tr key={t.id}>
                    <td>{t.showDate}</td>
                    <td>{t.city}</td>
                    <td>{t.showName}</td>
                    <td>{t.expert}</td>
                    <td>{t.catalogNo}</td>
                    <td>
                      {this.state.dogClasses.length > 0 && t.dogClassId != null
                        ? this.state.dogClasses.filter(
                            a => a.id === t.dogClassId
                          )[0].name
                        : ""}
                    </td>
                    <td>{t.result}</td>
                    <td className="text-right">
                      <EditButton
                        onClick={() => this.editEntity(t.id)}
                      ></EditButton>
                      <DeleteButton
                        onClick={() => this.deleteEntity(t.id)}
                        id={t.id}
                        entity="dogShowResult"
                      ></DeleteButton>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>

        {this.state.newStarted === false && (
          <button
            type="button"
            onClick={this.addData}
            className="btn btn-primary mt-3"
          >
            {t("Добавить результат")}
          </button>
        )}

        <div class="form-group form-check mt-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="allowToShowDogShowResults"
            name="allowToShowDogShowResults"
            checked={this.state.allowToShowDogShowResults}
            value={this.state.allowToShowDogShowResults}
            onChange={this.allowToShowChange}
          ></input>
          <label class="form-check-label" for="allBreeds">
            {t("Разрешить другим пользователям видеть результаты выставок")}
          </label>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DogShowResults)