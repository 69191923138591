import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class DeletionDialog extends Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div
          class="modal fade"
          id={this.props.id}
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {this.props.confirmationText == null ? t("Вы уверены что хотите удалить эту запись?") : this.props.confirmationText}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  onClick={this.props.onClick}
                >
                  {t("Удалить")}
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-secondary"
                >
                  {t("Отмена")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(DeletionDialog)