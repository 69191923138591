import React from "react";
import AuthComponent from "../../authComponent";
import { Link } from "react-router-dom";
import "./myDogs.css";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class MyDogs extends AuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      myDogs: []
    };
  }

  getMyDogs = () => {
    this.ajaxCall("Dog/GetMyDogs", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        myDogs: response
      });
    });
  };

  componentDidMount() {
    this.getMyDogs();
  }

  render() {
    const { t } = this.props;

    return (
      <div id="myDogs" className="container">
        <div class="one">
          <h1>Мои собаки</h1>
        </div>    

        <Helmet>
          <title>Мои собаки</title>
          <meta name="description" content="В данном разделе представлены все собаки вашего питомника или Ваши личные собаки"></meta>
        </Helmet>
        <div className="myDogsContainer">
          {this.state.myDogs &&
            this.state.myDogs.map(dog => (
              <Link to={"/myDog/" + dog.id}>
                <div key={dog.id} to={"/myDog/" + dog.id}>
                  <div className="card customCard mr-3 mb-3" key={dog.id}>
                    <img
                      src={this.imageUrl + "dogphotos/" + dog.photo}
                      className="card-img"
                      alt="..."
                      onError={e => (e.target.src = this.dogPlaceHolder)}
                    ></img>
                    <div className="card-body">
                      <h5 className="card-title">
                        <Link className={"nav-link"} to={"/myDog/" + dog.id}>
                          {dog.dogName}
                        </Link>
                      </h5>
                      <p className="card-text mb-0 pb-0">{dog.breed}</p>
                      {dog.birthDate && (
                        <p className="card-text">
                          <small className="text-muted">
                            {t("Дата рождения")}: {dog.birthDate}
                          </small>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          <Link to={"/myDog/0"}>
            <div key="0" to={"/myDog/0"}>
              <div className="card customCard mr-3 mb-3" key={0}>
                <img
                  src={this.dogPlaceHolder}
                  className="card-img"
                  alt="..."
                ></img>
                <div className="card-body">
                  <h5 id="addMyDogButton" className="card-title">
                    <Link className={"nav-link"} to={"/myDog/0"}>
                      {t("Добавить анкету")}...
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyDogs)