import React from "react";
import AuthComponent from "../../../authComponent";
import TerraDatePicker from "../../../components/terraDatePicker";
import { handleInputChange } from "../../../inputUtilities";
import DeleteButton from "../../../components/deleteButton";
import EditButton from "../../../components/editButton";
import { convertToDate } from "../../../date";
import { PuppyStatus, Gender } from "../../../enums";
import Puppy from "./puppy";
import { withTranslation } from 'react-i18next';

class Puppies extends AuthComponent {
  constructor(props) {
    super(props);

    const litter = this.props.litter;

    this.state = {
      savedSuccessfully: null,

      litter: litter,
      breedings: null,
      organizations: [],

      puppyKennelOrganizationId:
        litter == null ? null : litter.puppyKennelOrganizationId,
      dogBreedingId: litter == null ? 0 : litter.dogBreedingId,
      puppiesBirthDate: litter == null ? null : litter.puppiesBirthDate,
      litterName: litter == null ? "" : litter.litterName,
      displayLitterInMyKennel: litter == null ? true : litter.displayLitterInMyKennel,

      addEditPuppy: false,
      puppyId: null,
      puppies: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  successSaving = () => {
    setTimeout(() => {
      this.setState({ savedSuccessfully: false });
    }, 2000);

    this.setState({
      savedSuccessfully: true
    });
  };

  getKennelOrganizations = () => {
    this.ajaxCall("DogPublic/GetKennelOrganizations", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        organizations: response
      });
    });
  };

  getDogBreedings = () => {
    const excludeWithLitter = this.state.litter == null;
    this.ajaxCall(
      "Dog/GetDogBreedings/" + this.props.dogId + "/" + excludeWithLitter,
      {
        method: "get",
        headers: this.authHeaders
      }
    ).then(response => {
      this.setState({
        breedings: response
      });
    });
  };

  handleCalendarChangePuppiesBirthDate = date => {
    this.setState({
      puppiesBirthDate: date
    });
  };

  saveLitter = () => {
    let puppiesBirthDateVar;
    if (this.state.puppiesBirthDate.toLocaleDateString == null) {
      puppiesBirthDateVar = convertToDate(
        this.state.litter.puppiesBirthDateString
      );
    } else {
      puppiesBirthDateVar = this.state.puppiesBirthDate;
    }

    const bodyJson = {
      dogBreedingId: this.state.dogBreedingId,
      puppiesBirthDateString: puppiesBirthDateVar.toLocaleDateString("en-US"),
      firstDogId: this.props.dogId,
      puppyKennelOrganizationId: this.state.puppyKennelOrganizationId,
      litterName: this.state.litterName,
      displayLitterInMyKennel: this.state.displayLitterInMyKennel,
    };

    this.ajaxCall("Dog/SaveLitter", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(litter => {
      this.setState({
        litter
      });
      this.successSaving();
      this.props.refreshLitters();
    });
  };

  deleteLitter = dogBreedingId => {
    this.ajaxCall("Dog/DeleteLitter", {
      method: "post",
      headers: this.authHeaders,
      body: this.state.dogBreedingId
    }).then(response => {
      this.props.refreshLitters();
      this.props.cancelEditingLitter();
    });
  };

  getPuppies = () => {
    this.ajaxCall("Dog/GetPuppies/" + this.state.dogBreedingId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        puppyId: null,
        addEditPuppy: false,
        puppies: response
      });
    });
  };

  refreshPuppies = () => {
    this.getPuppies();
  };

  addEditPuppy = puppyId => {
    if (puppyId != null && puppyId === this.state.puppyId) {
      this.cancelEditingPuppy();
    } else {
      this.setState({
        addEditPuppy: true,
        puppyId: puppyId
      });
    }
  };

  deletePuppy = puppyId => {
    this.ajaxCall("Dog/DeletePuppy", {
      method: "post",
      headers: this.authHeaders,
      body: puppyId
    }).then(response => {
      this.cancelEditingPuppy();
      this.getPuppies();
    });
  };

  cancelEditingPuppy = () => {
    this.setState({
      puppyId: null,
      addEditPuppy: false
    });
  };

  componentDidMount() {
    this.getDogBreedings();
    this.getKennelOrganizations();
    this.getPuppies();
  }

  render() {
    const { t } = this.props;

    const {
      litter,
      dogBreedingId,
      puppiesBirthDate,
      litterName,
      puppyKennelOrganizationId,
      organizations,
      breedings,
      puppies,
      addEditPuppy,
      puppyId
    } = this.state;

    const savingDisabled =
      dogBreedingId === null ||
      dogBreedingId === "" ||
      dogBreedingId === 0 ||
      dogBreedingId === "0" ||
      puppiesBirthDate == null ||
      puppyKennelOrganizationId == null ||
      puppyKennelOrganizationId === "";

    return breedings != null && breedings.length === 0 ? (
      <div>
        <div class="alert alert-warning" role="alert">
          {t("Вы должны указать Вязку в разделе \"Статистика вязок\" и тогда добавление помета будет доступно")}
        </div>
        <button
          type="button"
          onClick={this.props.cancelEditingLitter}
          className="btn btn-primary mt-3 mr-2"
        >
          {t("Понятно")}
        </button>
      </div>
    ) : (
      <div id="litter">
        {this.state.savedSuccessfully === true && (
          <div class="alert alert-success" role="alert">
            {t("Сохранение прошло успешно")}
          </div>
        )}

        <div class="form-group">
          <label for="dogBreedingId">{t("Пара")}</label>
          <select
            disabled={litter == null ? false : true}
            class="form-control"
            id="dogBreedingId"
            name="dogBreedingId"
            value={dogBreedingId}
            onChange={this.handleInputChange}
          >
            <option value=""></option>
            {breedings &&
              breedings.map(a => (
                <option value={a.id}>
                  {a.dogName +
                    " & " +
                    a.matedDog +
                    " (Вязка от " +
                    a.firstBreedingDate +
                    ")"}
                </option>
              ))}
          </select>
        </div>

        <div class="form-group">
          <label for="genderId" className="mr-2 blockElement">
            {t("Дата рождения")}
          </label>
          <TerraDatePicker
            selected={puppiesBirthDate}
            onChange={this.handleCalendarChangePuppiesBirthDate}
            className="form-control"
          />
        </div>

        <div class="form-group">
          <label for="name">{t("Литера")}</label>
          <input
            type="text"
            class="form-control"
            id="litterName"
            name="litterName"
            autocomplete="off"
            value={litterName}
            onChange={this.handleInputChange}
          ></input>
        </div>

        <div class="form-group">
          <label for="puppyKennelOrganizationId">
            {t("Кинологическая организация")}
          </label>
          <select
            class="form-control"
            id="puppyKennelOrganizationId"
            name="puppyKennelOrganizationId"
            value={puppyKennelOrganizationId}
            onChange={this.handleInputChange}
          >
            <option value=""></option>
            {organizations &&
              organizations.map(a => <option value={a.id}>{a.name}</option>)}
          </select>
        </div>

        {this.props.hasKennel && (
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                autocomplete="off"
                id="displayLitterInMyKennel"
                name="displayLitterInMyKennel"
                onChange={this.handleInputChange}
                checked={this.state.displayLitterInMyKennel}
              ></input>
              <label class="form-check-label" for="displayLitterInMyKennel">
                {t("Отображать в разделе моего питомника")}
              </label>
            </div>
          )}

        <div className="actionButtons">
          <button
            type="button"
            onClick={this.saveLitter}
            disabled={savingDisabled}
            className="btn btn-primary mt-3 mr-2"
          >
            {t("Сохранить")}
          </button>

          {litter && (
            <DeleteButton
              key="deleteLitter"
              asIcon={false}
              entity="litter"
              onClick={this.deleteLitter}
            ></DeleteButton>
          )}
        </div>

        <div className="mt-3">
          {puppies && puppies.length > 0 && (
            <div id="puppies">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{t("Пол")}</th>
                    <th scope="col">{t("Кличка")}</th>
                    <th scope="col">{t("Статус")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {puppies &&
                    puppies.map(d => (
                      <>
                        <tr key={d.id} onClick={() => this.addEditPuppy(d.id)}>
                          <td>{Gender.getGender(d.genderId)}</td>
                          <td>{d.name}</td>
                          <td>{PuppyStatus.getStatus(d.status)}</td>
                          <td style={{ textAlign: "right" }}>
                            <EditButton></EditButton>
                            <DeleteButton
                              key={"deletionPuppy" + d.id}
                              asIcon={true}
                              entity="puppy"
                              onClick={() => this.deletePuppy(d.id)}
                            ></DeleteButton>
                          </td>
                        </tr>
                        {d.id === puppyId && (
                          <tr>
                            <td colSpan="4">
                              <Puppy
                                puppyId={d.id}
                                dogBreedingId={dogBreedingId}
                                refreshPuppies={this.refreshPuppies}
                                cancelEditingPuppy={this.cancelEditingPuppy}
                              ></Puppy>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {addEditPuppy && puppyId == null ? (
            <Puppy
              puppyId={puppyId}
              dogBreedingId={dogBreedingId}
              refreshPuppies={this.refreshPuppies}
              cancelEditingPuppy={this.cancelEditingPuppy}
            ></Puppy>
          ) : (
            litter != null && <button
              type="button"
              onClick={() => this.addEditPuppy(null)}
              className="btn btn-primary mt-3"
            >
              {t("Добавить щенка")}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Puppies)