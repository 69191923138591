export function getCulture() {
    let language;	
    if (window.navigator.languages) {	    
        language = window.navigator.languages[0];	
    } else {	    
        language = window.navigator.userLanguage || window.navigator.language;	
    }
    console.log('window.navigator');
    console.log(window.navigator);
    return language;
}

