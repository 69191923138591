import React, { Component } from "react";
import Octicon, { Pin } from "@primer/octicons-react";

export default class PinButton extends Component {
  render() {
    return (
      <>
        <span
          title={this.props.title}
          class="clickableIcon mr-2"
          onClick={this.props.onClick}
        >
          <Octicon icon={Pin} size="medium" />
        </span>
      </>
    );
  }
}
