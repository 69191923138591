import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import PleaseRegisterButton from "../../components/pleaseRegisterButton";
import HandlerCalendar from "./handlerCalendar";
import "./handlers.css";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class Handler extends NonAuthComponent {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      userId: id,
      handler: {},
      photos: []
    };
  }

  getHandlerPublicInfo = () => {
    if (this.state.dogId === 0) {
      return;
    }

    this.ajaxCall("HandlerPublic/GetHandlerPublicInfo/" + this.state.userId, {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        handler: response,
        photos: [response.photo]
      });
    });
  };

  componentDidMount() {
    this.getHandlerPublicInfo();
  }

  render() {
    const { t } = this.props;

    const { handler, photos } = this.state;

    return (
      <div id="handler" className="container">
        <div id="carousel" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            {photos &&
              photos.map(photo => (
                <li
                  data-target="#carousel"
                  data-slide-to={photos.indexOf(photo)}
                  class={photos.indexOf(photo) === 0 ? "active" : ""}
                ></li>
              ))}
          </ol>
          <div class="carousel-inner">
            {photos &&
              photos.map(photo => (
                <div
                  class={
                    "carousel-item" +
                    (photos.indexOf(photo) === 0 ? " active" : "")
                  }
                >
                  <img
                    src={
                      this.imageUrl +
                      "userphotos/" +
                      photo +
                      "?" +
                      this.state.imageHash
                    }
                    onError={e =>
                      (e.target.src =
                        this.imageUrl + "common/userplaceholder.svg")
                    }
                    class="center-block"
                    alt={"Хендлер " + handler.handlerName}
                  ></img>
                </div>
              ))}
          </div>
        </div>

        {this.currentUserId == null && (
          <PleaseRegisterButton text="Для общения с хендлером пожалуйста зарегистрируйтесь">PleaseRegisterButton</PleaseRegisterButton>
        )}

        <div class="one">
          <h1>Хендлер {handler.handlerName}</h1>
        </div>    

        <Helmet>
          <title>{"Хендлер " + handler.handlerName}</title>
          <meta name="description" content={`Хендлер ${handler.handlerName} Стоимость показа ${handler.cost}. ${handler.aboutMe} Породы ${handler.allBreeds ? t("Выставляю все породы") : handler.handlerBreeds}`.replaceAll("null", "")}></meta>
        </Helmet>

        <table class="table table-striped mt-5">
          <tbody>
            <tr>
              <td>{t("Имя")}</td>
              <td>{handler.handlerName}</td>
            </tr>
            <tr>
              <td>{t("Стоимость показа")}</td>
              <td>{handler.cost}</td>
            </tr>
            {this.currentUserId && (
              <tr>
                <td>{t("Телефон")}</td>
                <td>{handler.phone}</td>
              </tr>
            )}
            <tr>
              <td>{t("Обо мне")}</td>
              <td>{handler.aboutMe}</td>
            </tr>
            <tr>
              <td>{t("Породы")}</td>
              <td>
                {handler.allBreeds
                  ? t("Выставляю все породы")
                  : handler.handlerBreeds}
              </td>
            </tr>
          </tbody>
        </table>

        <HandlerCalendar userId={this.state.userId}></HandlerCalendar>
      </div>
    );
  }
}

export default withTranslation()(Handler)