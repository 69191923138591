import { apiUrl } from "./apiServer";
import { getAccessToken } from "./authentification";
import { getCulture } from './localizer';
import Cookies from "js-cookie";

export const ajaxCaller = (url, parameters) => {
  return ajaxNonJsonCaller(url, parameters).then(response => {
    return response.json();
  });
};

export const ajaxNonJsonCaller = (url, parameters) => {
  if (document.getElementById("globalSpinner") != null) {
    document.getElementById("globalSpinner").style.display = "block";
  }

  return fetch(apiUrl + url, parameters).then(response => {
    if (document.getElementById("globalSpinner") != null) {
      document.getElementById("globalSpinner").style.display = "none";
    }
    return response;
  });
};

export const authHeaders = new Headers({
  Authorization: "Bearer " + getAccessToken(),
  "Content-Type": "application/json",
  Accept: "application/json",
  "Accept-Language": /*getCulture()*/"ru",
  "Language": /*Cookies.get("language")*/"ru"
});

export const authHeadersForFiles = new Headers({
  Authorization: "Bearer " + getAccessToken()
});

export const nonAuthHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  "Accept-Language": /*getCulture()*/"ru",
  "Language": /*Cookies.get("language")*/"ru"
});
