import React from "react";
import AuthComponent from "../../../authComponent";
import { handleInputChange } from "../../../inputUtilities";
import DeleteButton from "../../../components/deleteButton";
import EditButton from "../../../components/editButton";
import CancelButton from "../../../components/cancelButton";
import SaveButton from "../../../components/saveButton";
import { withTranslation } from 'react-i18next';

class KennelNews extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      id: 0,
      newStarted: false,
      text: null
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  getData = () => {
    this.ajaxCall("Kennel/GetMyNews", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        data: response
      });
    });
  };

  addData = () => {
    this.setState({
      newStarted: true,
      data: this.state.data.concat({
        id: 0,
        isNew: true,
        text: ""
      })
    });
  };

  cancelEdit = () => {
    let data = this.state.data;
    if (this.state.id > 0) {
      data = data.map(a => {
        if (a.id === this.state.id) {
          a.editMode = false;
        }
        return a;
      });
    } else {
      data.splice(data.length - 1, 1);
    }

    this.setState({
      data,
      id: 0,
      newStarted: false,
      text: ""
    });
  };

  saveData = () => {
    const bodyJson = {
      id: this.state.id,
      text: this.state.text,
      kennelId: 0,
      timeStamp: "01/01/2000"
    };

    const newData = this.state.id === 0;

    this.ajaxCall("Kennel/SaveMyNews", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      let data = this.state.data;
      if (newData) {
        data.splice(data.length - 1, 1);
        data = data.concat(response.entity);
      } else {
        data = data.map(a => {
          if (a.id === response.entity.id) {
            return response.entity;
          } else {
            return a;
          }
        });
      }

      this.setState({
        id: 0,
        data: data,
        newStarted: false,
        text: ""
      });
    });
  };

  editEntity = id => {
    let text = "";
    let identifier = 0;

    this.setState({
      newStarted: true,
      data: this.state.data
        .filter(a => !a.isNew)
        .map(a => {
          if (a.id === id) {
            a.editMode = true;
            text = a.text;
            identifier = a.id;
          } else {
            a.editMode = false;
          }
          return a;
        }),
      text,
      id: identifier
    });
  };

  deleteEntity = kennelNewsId => {
    this.ajaxCall("Kennel/DeleteMyNews", {
      method: "post",
      headers: this.authHeaders,
      body: kennelNewsId
    }).then(response => {
      this.setState({
        data: this.state.data.filter(a => a.id !== kennelNewsId)
      });
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { t } = this.props;

    const data = this.state.data;
    const saveButtonDisabled =
      this.state.text == null || this.state.text === "";

    return (
      <div id="healthTable">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t("Новости")}</th>
              <th scope="col">{t("Дата")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map != null &&
              data.map(t =>
                t.isNew || t.editMode ? (
                  <tr key="0">
                    <td>
                      <textarea
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="text"
                        name="text"
                        value={this.state.text}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </td>
                    <td></td>
                    <td className="text-right">
                      <SaveButton
                        onClick={this.saveData}
                        disabled={saveButtonDisabled}
                      ></SaveButton>
                      <CancelButton onClick={this.cancelEdit}></CancelButton>
                    </td>
                  </tr>
                ) : (
                  <tr key={t.id}>
                    <td>{t.text}</td>
                    <td>{t.timeStamp}</td>
                    <td className="text-right">
                      <EditButton
                        onClick={() => this.editEntity(t.id)}
                      ></EditButton>
                      <DeleteButton
                        onClick={() => this.deleteEntity(t.id)}
                        id={t.id}
                        entity={"kennelNews"}
                      ></DeleteButton>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>

        {this.state.newStarted === false && (
          <button
            type="button"
            onClick={this.addData}
            className="btn btn-primary mt-3"
          >
            {t("Добавить новость")}
          </button>
        )}
      </div>
    );
  }
}

export default withTranslation()(KennelNews)