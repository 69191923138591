import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import { Link } from "react-router-dom";
import "./handlers.css";
import DiscussionButton from "../../components/discussionButton";
import LocationButton from "../../components/locationButton";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class Handlers extends NonAuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      handlers: []
    };
  }

  getHandlers = () => {
    this.ajaxCall("HandlerPublic/GetHandlers", {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        handlers: response
      });
    });
  };

  discuss = userId => {
    this.ajaxCall("Chat/StartChatWithHandler", {
      method: "post",
      headers: this.authHeaders,
      body: userId.toString()
    }).then(response => {
      window.location.href = "/chat/" + response.chatId;
    });
  };

  componentDidMount() {
    this.getHandlers();
  }

  render() {
    const { t } = this.props;

    return (
      <div id="handlers" className="container">
        <Helmet>
          <title>Хендлеры TERRA-DOG</title>
          <meta name="description" content="Хендлеры - помощники в выставках. На этой странице Питомники собак пользуются услугами наших хендлеров для подготовки и выставки наших собак"></meta>
        </Helmet>
        <div className="handlerContainer">
          <div class="one">
            <h1 >
              Хендлеры
            </h1>
          </div>
          {this.state.handlers &&
            this.state.handlers.map(handler => (
              <div className="card customCard mr-3 mb-3" key={handler.handlerId}>
                {this.currentUserId && (
                  <div className="actionButtons">
                    <DiscussionButton
                      onClick={() => {
                        this.discuss(handler.userId);
                      }}
                    ></DiscussionButton>
                  </div>
                )}

                <img
                  src={this.imageUrl + "userphotos/" + handler.photo}
                  className="card-img"
                  alt={"Хендлер " + handler.handlerName}
                  onError={e =>
                    (e.target.src =
                      this.imageUrl + "common/userplaceholder.svg")
                  }
                ></img>
                <div className="card-body">
                  <h5 className="card-title">
                    <Link
                      className={"nav-link"}
                      to={"/handler/" + handler.userId}
                    >
                      {handler.handlerName}
                    </Link>
                  </h5>
                  <p className="card-text mb-0 pb-0">
                    {t("Стоимость показа")}: {handler.cost}
                  </p>
                  <p className="card-text mb-0 pb-0">
                    <small className="text-muted">
                      {t("Породы")}:{" "}
                      {handler.allBreeds === false
                        ? handler.handlerBreeds != null
                          ? handler.handlerBreeds.length > 50
                            ? handler.handlerBreeds.substr(0, 47) + "..."
                            : handler.handlerBreeds
                          : ""
                        : "Все"}
                    </small>
                  </p>
                  {handler.city && (
                    <p className="card-text mb-0 pb-0">
                      <LocationButton></LocationButton>
                      <small> {handler.city}</small>
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Handlers)