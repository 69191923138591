//export const webSiteUrl = "https://localhost:44385/";
export const webSiteUrl = "https://santa.today/";


export const apiUrl = webSiteUrl + "api/";
export const imageContainerUrl =
  "https://terradogstorage.blob.core.windows.net/";

export const dogPlaceHolder = imageContainerUrl + "/common/dogplaceholder.svg";

//HTTPS Problem in publishing SecurityError: Failed to construct 'WebSocket': An insecure WebSocket connection may not be initiated from a page loaded over HTTPS  https://stackoverflow.com/questions/59359280/react-app-error-failed-to-construct-websocket-an-insecure-websocket-connecti
// OR!:
//https://stackoverflow.com/questions/59241291/securityerror-failed-to-construct-websocket-when-i-upload-react-js-applicatio
/*
so set in file: node_modules/react-dev-utils/webpackHotDevClient.js in line 62
the next code: protocol: window.location.protocol === 'https:' ? 'wss' : 'ws',
OR
dowgrade the react scripts:
npm install react-script@3.1.1
*/

/*
INSTALL OR REBUILD!!!:
react-infinite-scroll-component

ебучий реакт скриптс должен быть таким "react-scripts": "3.1.1", в версии 3.3 ошибка сцуко!
*/
