import React, { Component } from "react";
import { handleInputChange } from "../../../inputUtilities";
import { ajaxCaller, authHeaders } from "../../../ajaxCaller";
import { withTranslation } from 'react-i18next';

class HandlerEventDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      handlerAvailabilityId: 0,
      dogClassId: null,
      genderId: null,
      availabilityId: null,
      dogName: "",
      availabilities: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  selectCell = (
    dogClassId,
    genderId,
    handlerAvailabilityId,
    availabilityId,
    dogName
  ) => {
    this.setState({
      dogClassId,
      genderId,
      handlerAvailabilityId,
      availabilityId: handlerAvailabilityId > 0 ? availabilityId : 2,
      dogName
    });
  };

  deleteAvailability = () => {
    this.saveAvailability(1);
  }

  saveAvailability = (tempAvailability) => {
    const bodyJson = {
      id: this.state.handlerAvailabilityId,
      handlerEventBreedId: this.props.handlerEventBreedId,
      dogClassId: this.state.dogClassId,
      genderId: this.state.genderId,
      availabilityId: tempAvailability === undefined ? this.state.availabilityId : tempAvailability,
      dogName: this.state.dogName
    };

    ajaxCaller("Handler/SaveHandlerAvailability", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      this.setState({
        handlerAvailabilityId: 0,
        dogClassId: null,
        genderId: null,
        availabilityId: 1,
        dogName: "",
        availabilities:
          response.deleted
            ? [...this.state.availabilities.filter(
              a => a.id !== response.id
            )] :
            [
              ...this.state.availabilities.filter(
                a => a.id !== response.id
              ),
              response.handlerAvailability
            ]
      });
    });
  };

  getHandlerAvailabilities = () => {
    ajaxCaller(
      "HandlerPublic/GetHanderAvailabilities/" + this.props.handlerEventBreedId,
      {
        method: "get",
        headers: authHeaders
      }
    ).then(response => {
      this.setState({
        availabilities: response
      });
    });
  };

  componentDidMount() {
    this.getHandlerAvailabilities();
  }

  render() {
    const { t } = this.props;

    const availabilities = this.state.availabilities;

    return (
      <div>
        <table className="handlerEventDetails" class="table table-bordered">
          <thead class="bg-primary">
            <tr class="table-primary">
              <th scope="col">{t("Класс")}</th>
              <th scope="col">{t("Кобель")}</th>
              <th scope="col">{t("Сука")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.dogClasses &&
              this.props.dogClasses.map(dogClass => {
                const male = availabilities.filter(
                  availability =>
                    availability.dogClassId === dogClass.id &&
                    availability.genderId === 1
                );
                const female = availabilities.filter(
                  availability =>
                    availability.dogClassId === dogClass.id &&
                    availability.genderId === 2
                );

                return (
                  <tr>
                    <th scope="row">{dogClass.name}</th>
                    <td
                      data-toggle="modal"
                      data-target={
                        "#availabilityModal" + this.props.handlerEventBreedId
                      }
                      class={
                        "clickableItem " +
                        (male.length === 1
                          ? "table-secondary"
                          : "table-success")
                      }
                      onClick={() =>
                        this.selectCell(
                          dogClass.id,
                          1,
                          male.length === 1 ? male[0].id : 0,
                          male.length === 1 ? 2 : 1,
                          male.length === 1 ? male[0].dogName : ""
                        )
                      }
                    >
                      {male.length === 1 ? male[0].dogName : ""}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target={
                        "#availabilityModal" + this.props.handlerEventBreedId
                      }
                      class={
                        "clickableItem " +
                        (female.length === 1
                          ? "table-secondary"
                          : "table-success")
                      }
                      onClick={() =>
                        this.selectCell(
                          dogClass.id,
                          2,
                          female.length === 1 ? female[0].id : 0,
                          female.length === 1 ? 2 : 1,
                          female.length === 1 ? female[0].dogName : ""
                        )
                      }
                    >
                      {female.length === 1 ? female[0].dogName : ""}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div
          class="modal fade"
          id={"availabilityModal" + this.props.handlerEventBreedId}
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{t("Забронировать класс / Недоступен")}</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-check">
                  <input
                    type="text"
                    class="row form-control"
                    name="dogName"
                    value={this.state.dogName}
                    onChange={this.handleInputChange}
                    placeholder="Имя собаки"
                  ></input>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("Закрыть")}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => this.saveAvailability()}
                  data-dismiss="modal"
                >
                  {t("Сохранить")}
                </button>
                {this.state.handlerAvailabilityId > 0 && (<button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.deleteAvailability}
                  data-dismiss="modal"
                >
                  {t("Удалить")}
                </button>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HandlerEventDetails)