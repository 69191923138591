import React, { Component } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./handler.css";
import { Modal } from "react-bootstrap";
import { ajaxCaller, authHeaders } from "../../../ajaxCaller";
import Select from "react-select";
import { handleInputChange } from "../../../inputUtilities";
import { getCulture } from "../../../localizer";
import HandlerEvent from "./handlerEvent";
import { withTranslation } from 'react-i18next';

class HandlerCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: null,
      modalIsOpen: false,
      myEvents: [],
      startDate: null,
      endDate: null,
      showName: "",
      selectedBreeds: [],
      breeds: [],
      onlyOneBreed: true,
      refreshedBreeds: false
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  hidePopup = () => {
    this.setState({ modalIsOpen: false });
  };

  deleteEvent = () => {
    const event = this.state.selectedEvent;
    if (event != null) {
      ajaxCaller("Handler/DeleteEvent/" + event.id, {
        method: "post",
        headers: authHeaders
      }).then(response => {
        if (!response.isError) {
          this.setState({
            myEvents: this.state.myEvents.filter(a => a.id !== event.id),
            selectedEvent: null
          });
        }
      });
    }
  };

  saveEvent = () => {
    const bodyJson = {
      date: this.state.startDate.toLocaleDateString("en-US"),
      eventName: this.state.showName,
      breedIds:
        this.state.selectedBreeds == null
          ? []
          : this.state.selectedBreeds.map(a => a.id)
    };

    ajaxCaller("Handler/AddEvent", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      if (!response.isError) {
        this.setState({
          modalIsOpen: false,
          myEvents: [
            ...this.state.myEvents,
            {
              id: response.handlerEventId,
              title: this.state.showName,
              allDay: true,
              start: this.state.startDate,
              end: this.state.startDate
            }
          ]
        });
      }
    });
  };

  handleSelect = ({ start }) => {
    this.setState({
      modalIsOpen: true,
      showName: "",
      selectedBreeds:
        this.state.breeds.length === 1 ? [this.state.breeds[0]] : [],
      startDate: start,
      endDate: start
    });
  };

  handleBreedChange = options => {
    this.setState({ selectedBreeds: options });
  };

  getBreeds = () => {
    ajaxCaller("Handler/GetHandlerBreeds", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        breeds: response,
        onlyOneBreed: response.length === 1,
        selectedBreeds: response.length === 1 ? [response[0]] : []
      });
    });
  };

  getHandlerEvents = () => {
    ajaxCaller("Handler/GetHandlerEvents", {
      method: "get",
      headers: authHeaders
    }).then(events => {
      this.setState({
        myEvents: events.map(event => ({
          id: event.id,
          title: event.eventName,
          allDay: true,
          start: new Date(event.date),
          end: new Date(event.date)
        }))
      });
    });
  };

  selectEvent = event => {
    this.setState({
      selectedEvent: event
    });
  };

  backToCalendar = () => {
    this.setState({
      selectedEvent: null
    });
  };

  componentDidMount() {
    this.getBreeds();
    this.getHandlerEvents();
  }

  componentDidUpdate() {
    if (
      this.props.refreshBreeds === true &&
      this.state.refreshedBreeds === false
    ) {
      this.getBreeds();
      this.setState({
        refreshedBreeds: true
      });
    } else if (
      this.props.refreshBreeds === false &&
      this.state.refreshedBreeds === true
    ) {
      this.setState({
        refreshedBreeds: false
      });
    }
  }

  render() {
    const { t } = this.props;

    const localizer = momentLocalizer(moment);

    const savingAvailable =
      this.state.startDate != null &&
      this.state.showName !== "" &&
      this.state.showName != null &&
      this.state.selectedBreeds.length > 0;

    return (
      <>
        <div
          id="handlerCalendar"
          style={{
            display: this.state.selectedEvent == null ? "block" : "none"
          }}
        >
          <Calendar
            selectable
            culture={getCulture()}
            localizer={localizer}
            events={this.state.myEvents}
            startAccessor="start"
            endAccessor="end"
            defaultView={Views.MONTH}
            onSelectSlot={this.handleSelect}
            onSelectEvent={event => this.selectEvent(event)}
            date={this.state.selectedDay}
            onNavigate={date => {
              this.setState({ selectedDate: date });
            }}
            views={["month"]}
            messages={{
              previous: "<",
              next: ">",
              today: t("Сегодня")
            }}
          />
        </div>

        {this.state.selectedEvent && (
          <>
            <div id="handlerEventActions">
              <button
                type="button"
                class="btn btn-danger"
                style={{ marginRight: "10px" }}
                data-toggle="modal"
                data-target="#confirmDeletion"
              >
                {t("Удалить событие")}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.backToCalendar}
              >
                {t("Назад к календарю")}
              </button>
            </div>
            <HandlerEvent event={this.state.selectedEvent}></HandlerEvent>
          </>
        )}

        <div
          class="modal fade"
          id="confirmDeletion"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {t("Вы уверены что хотите удалить данное событие?")}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  onClick={this.deleteEvent}
                >
                  {t("Удалить")}
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-secondary"
                >
                  {t("Отмена")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.modalIsOpen}
          onHide={this.hidePopup}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Событие на")}{" "}
              {this.state.startDate &&
                this.state.startDate.toLocaleDateString()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group">
              <label for="showName">{t("Название выставки")}</label>
              <input
                type="text"
                class="form-control"
                id="showName"
                name="showName"
                value={this.state.showName}
                onChange={this.handleInputChange}
              ></input>
            </div>
            {this.state.onlyOneBreed === false && (
              <div class="form-group">
                <label for="breeds">{t("Породы")}</label>
                <Select
                  id="breeds"
                  value={this.state.selectedBreeds}
                  onChange={this.handleBreedChange}
                  options={this.state.breeds}
                  isMulti={true}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-primary"
              disabled={!savingAvailable}
              onClick={this.saveEvent}
            >
              {t("Сохранить")}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={this.hidePopup}
            >
              {t("Отменить")}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(HandlerCalendar)