import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class PleaseRegisterButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMessage: false,
    };
  }

  openRegistrationPage = () => {
    window.location.replace("/");
  };

  render() {
    const { t, text } = this.props;

    return (
      <div style={{cursor: "pointer"}} class="alert alert-warning mt-5" onClick={this.openRegistrationPage} role="alert">
        {t(text)}
      </div>
    );
  }
}

export default withTranslation()(PleaseRegisterButton);
