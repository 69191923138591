import React from "react";
import AuthComponent from "../../../authComponent";
import { handleInputChange } from "../../../inputUtilities";
import TerraDatePicker from "../../../components/terraDatePicker";
import DeleteButton from "../../../components/deleteButton";
import EditButton from "../../../components/editButton";
import CancelButton from "../../../components/cancelButton";
import SaveButton from "../../../components/saveButton";
import { convertToDate } from '../../../date';
import { withTranslation } from 'react-i18next';

class HealthTable extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      id: 0,
      newStarted: false,
      startDate: null,
      endDate: null,
      medicineName: ""
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  handleCalendarChangeStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleCalendarChangeEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  getData = () => {
    this.ajaxCall(
      "Dog/Get" + this.props.medicineType + "List/" + this.props.dogId,
      {
        method: "get",
        headers: this.authHeaders
      }
    ).then(response => {
      this.setState({
        data: response
      });
    });
  };

  addData = () => {
    this.setState({
      newStarted: true,
      data: this.state.data.concat({
        id: 0,
        isNew: true,
        dogId: this.props.dogId,
        startDate: null,
        endDate: null,
        medicineName: ""
      })
    });
  };

  cancelEdit = () => {
    let data = this.state.data;
    if (this.state.id > 0) {
      data = data.map(a => {
        if (a.id === this.state.id) {
          a.editMode = false;
        }
        return a;
      });
    }
    else {
      data.splice(data.length - 1, 1);
    }

    this.setState({
      data,
      id: 0,
      newStarted: false,
      startDate: null,
      endDate: null,
      medicineName: ""
    });
  };

  saveData = () => {
    const bodyJson = {
      id: this.state.id,
      dogId: this.props.dogId,
      startDate: this.state.startDate.toLocaleDateString('en-US'),
      endDate: this.state.endDate.toLocaleDateString('en-US'),
      medicineName: this.state.medicineName
    };

    const newData = this.state.id === 0;

    this.ajaxCall("Dog/Save" + this.props.medicineType, {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      let data = this.state.data;
      if (newData) {
        data.splice(data.length - 1, 1);
        data = data.concat(response.entity);
      } else {
        data = data.map(a => {
          if (a.id === response.entity.id) {
            return response.entity;
          } else {
            return a;
          }
        });
      }

      this.setState({
        id: 0,
        data: data,
        newStarted: false,
        startDate: null,
        endDate: null,
        medicineName: ""
      });
    });
  };

  editEntity = id => {
    let startDate = null;
    let endDate = null;
    let medicineName = "";
    let identifier = 0;

    this.setState({
      newStarted: true,
      data: this.state.data
        .filter(a => !a.isNew)
        .map(a => {
          if (a.id === id) {
            a.editMode = true;
            startDate = convertToDate(a.startDate);
            endDate = convertToDate(a.endDate);
            medicineName = a.medicineName;
            identifier = a.id;
          } else {
            a.editMode = false;
          }
          return a;
        }),
      startDate,
      endDate,
      medicineName,
      id: identifier
    });
  };

  deleteEntity = dogBreedingId => {
    this.ajaxCall("Dog/Delete" + this.props.medicineType, {
      method: "post",
      headers: this.authHeaders,
      body: dogBreedingId
    }).then(response => {
      this.setState({
        data: this.state.data.filter(a => a.id !== dogBreedingId)
      });
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { t } = this.props;

    const data = this.state.data;
    const saveButtonDisabled =
      this.state.startDate == null || this.state.endDate == null;

    return (
      <div id="healthTable">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t("Текущая")}</th>
              <th scope="col">{t("Следующая")}</th>
              <th scope="col">{t("Препарат")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map != null &&
              data.map(t =>
                t.isNew || t.editMode ? (
                  <tr key="0">
                    <td>
                      <TerraDatePicker
                        selected={this.state.startDate}
                        onChange={this.handleCalendarChangeStartDate}
                        id="startDate"
                        name="startDate"
                        key="startDateCalendar"
                        className="form-control"
                        autocomplete="off"
                      />
                    </td>
                    <td>
                      <TerraDatePicker
                        selected={this.state.endDate}
                        onChange={this.handleCalendarChangeEndDate}
                        id="endDate"
                        name="endDate"
                        key="endDateCalendar"
                        className="form-control"
                        autocomplete="off"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        id="medicineName"
                        name="medicineName"
                        value={this.state.medicineName}
                        onChange={this.handleInputChange}
                      ></input>
                    </td>
                    <td className="text-right">
                      <SaveButton
                        onClick={this.saveData}
                        disabled={saveButtonDisabled}
                      ></SaveButton>
                      <CancelButton onClick={this.cancelEdit}></CancelButton>
                    </td>
                  </tr>
                ) : (
                  <tr key={t.id}>
                    <td>{t.startDate}</td>
                    <td>{t.endDate}</td>
                    <td>{t.medicineName}</td>
                    <td className="text-right">
                      <EditButton
                        onClick={() => this.editEntity(t.id)}
                      ></EditButton>
                      <DeleteButton
                        onClick={() => this.deleteEntity(t.id)}
                        id={t.id}
                        entity={this.props.medicineType}
                      ></DeleteButton>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>

        {this.state.newStarted === false && (
          <button
            type="button"
            onClick={this.addData}
            className="btn btn-primary mt-3"
          >
            {this.props.addButtonName}
          </button>
        )}
      </div>
    );
  }
}

export default withTranslation()(HealthTable)