import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
// import Landing from "./pages/landing";
import LogOut from "./pages/logout";
import GoogleApiWrapper from "./pages/friendForWalk";
import Chats from "./pages/chat/chats";
import Chat from "./pages/chat/chat";
import Dogs from "./pages/dogs";
import Dog from "./pages/dogs/dog";
import FavoriteDogs from "./pages/favoriteDogs/index";
import MyDogs from "./pages/myDogs";
import MyDog from "./pages/myDogs/myDog";
import Cabinet from "./pages/cabinet";
import Handler from "./pages/handlers/handler";
import Handlers from "./pages/handlers";
import Kennel from "./pages/kennels/kennel";
import Kennels from "./pages/kennels/kennels";
import UserConfirm from "./pages/userConfirm";
import UserConfirmNotice from "./pages/userConfirmNotice";
import FacebookPage from "./pages/facebookPage";
import Agreement from "./pages/agreement";
import { isAuthenticated } from "./authentification";
import { imageContainerUrl } from "./apiServer";
import { webSiteUrl } from "./apiServer";
import * as signalR from "@aspnet/signalr";
import { getAccessToken } from "./authentification";
import { ajaxCaller, authHeaders } from "./ajaxCaller";
import moment from "moment";
import "moment/locale/ru";
import { getCulture } from "./localizer";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
// import ReactFlagsSelect from "react-flags-select";

//import css module
import "react-flags-select/css/react-flags-select.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: isAuthenticated(),
      currentPage: window.location.pathname,
      numberOfUnreadMessages: 0,
    };

    this.unreadMessagesListener();
  }

  getCurrentLanguage = () => {
    if (Cookies.get("language") !== 'RU') {
      this.setCurrentLanguage('RU'); 
    }
     
    // if (Cookies.get("language") == null) {
    //   let userLang = navigator.language || navigator.userLanguage; 
    //   if (userLang.length > 1) {
    //     userLang = userLang.substr(0, 2).toUpperCase();
    //   }

    //   if (userLang === "RU" || userLang === "UA" || userLang === "PL") {
    //     this.setCurrentLanguage(userLang);  
    //   }
    //   else if (userLang === "EN") {
    //     this.setCurrentLanguage("US");
    //     userLang = "US";
    //   }
    //   else {
    //     this.setCurrentLanguage("RU");
    //     userLang = "RU";
    //   }

    //   return userLang;
    // } else {
    //   return Cookies.get("language");
    // }
  };

  setCurrentLanguage = (language) => {
    Cookies.set("language", language);
  };

  setActiveLink = (pageUrl) => {
    this.setState({
      currentPage: pageUrl,
    });
  };

  listener = (location, action) => {
    this.setState({ currentPage: location.pathname });
  };

  increaseMessageCounter = () => {
    this.setState({
      numberOfUnreadMessages: this.state.numberOfUnreadMessages + 1,
    });
  };

  decreaseMessageCounter = () => {
    if (this.state.currentPage === "/chats") {
      this.setState({
        numberOfUnreadMessages: this.state.numberOfUnreadMessages - 1,
      });
    }
  };

  setMessageCounter = (messagesNumber) => {
    this.setState({
      numberOfUnreadMessages: messagesNumber,
    });
  };

  getMessageCounter = () => {
    return this.state.numberOfUnreadMessages;
  };

  unreadMessagesListener = () => {
    const protocol = new signalR.JsonHubProtocol();

    const transport = signalR.HttpTransportType.WebSockets;

    const options = {
      transport,
      logMessageContent: true,
      logger: signalR.LogLevel.Trace,
      accessTokenFactory: () => getAccessToken(),
    };

    // create the connection instance
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(webSiteUrl + "chat", options)
      .withHubProtocol(protocol)
      .build();
    this.connection.on("increaseCounter", this.increaseMessageCounter);

    this.connection.start();
  };

  componentDidMount() {
    moment.locale(getCulture());

    if (this.state.authenticated) {
      ajaxCaller("Chat/GetUnreadMessages", {
        method: "get",
        headers: authHeaders,
      }).then((response) => {
        this.setState({
          numberOfUnreadMessages: response.unreadMessages,
        });
      });
    }
  }

  changeLanguage = (countryCode) => {
    this.setCurrentLanguage(countryCode);

    this.props.i18n.changeLanguage(countryCode);

    window.location.reload();
  };

  render() {
    const { t } = this.props;

    const authenticated = this.state.authenticated;
    const currentPage = this.state.currentPage;

    return (
      <div className="App">
        <div
          id="globalSpinner"
          class="spinner-border text-primary globalSpinner"
          role="status"
        >
          <span class="sr-only">{t("Загрузка")}</span>
        </div>
        <Router>
          {currentPage != null && currentPage.length > 3 && currentPage[currentPage.length - 1] === "/" && <Redirect from="/" to="/" />}
          {!authenticated && (
            <nav
              class="navbar navbar-expand-md navbar-dark bg-primary"
            >
              <div class="mx-auto order-0">
                <a class="navbar-brand mx-auto" href="/">
                  <img
                    src={imageContainerUrl + "/common/logo.png"}
                    className="mainLogo"
                    alt="terra-dog.com"
                  ></img>
                </a>
                <button
                  class="navbar-toggler ml-2"
                  type="button"
                  data-toggle="collapse"
                  data-target=".dual-collapse2"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                id="mainMenu"
                class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2"
              >
                {/* https://www.npmjs.com/package/react-flags-select */}
                {/* <ReactFlagsSelect
                  defaultCountry={currentLanguage.toUpperCase()}
                  countries={["US", "UA", "RU", "PL"]}
                  onSelect={this.changeLanguage}
                  customLabels={{"US": "English","UA": "Українська","RU": "Русский", "PL": "Polskie"}}
                /> */}
                <ul class="navbar-nav mr-auto">
                  {/* <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a className="nav-link " href="/facebookPage">
                      Facebook
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/dogs"
                      className={
                        "nav-link " +
                        (currentPage.includes("/dogs") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/dogs")}
                    >
                      {t("Собаки")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/handlers"
                      className={
                        "nav-link " +
                        (currentPage.includes("/handler") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/handlers")}
                    >
                      {t("Найти хендлера")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/kennels"
                      className={
                        "nav-link " +
                        (currentPage.includes("/kennel") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/kennels")}
                    >
                      {t("Питомники")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/breedingdogs"
                      className={
                        "nav-link " +
                        (currentPage.includes("/breedingdogs") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/breedingdogs")}
                    >
                      {t("Вязки")}
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          )}
          {authenticated && (
            <nav
              class="navbar navbar-expand-md navbar-dark bg-primary"
            >
              <div class="mx-auto order-0">
                <a class="navbar-brand mx-auto" href="/">
                  <img
                    src={imageContainerUrl + "/common/logo.png"}
                    className="mainLogo"
                    alt="terra-dog.com"
                  ></img>
                </a>
                <button
                  class="navbar-toggler ml-2"
                  type="button"
                  data-toggle="collapse"
                  data-target=".dual-collapse2"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                id="mainMenu"
                class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2"
              >
                {/* https://www.npmjs.com/package/react-flags-select */}
                {/* <ReactFlagsSelect
                  defaultCountry={currentLanguage.toUpperCase()}
                  countries={["US", "UA", "RU", "PL"]}
                  onSelect={this.changeLanguage}
                  customLabels={{"US": "English","UA": "Українська","RU": "Русский", "PL": "Polskie"}}
                /> */}
                <ul class="navbar-nav mr-auto">
                  {/* <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a className="nav-link " href="/facebookPage">
                      Facebook
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/dogs"
                      className={
                        "nav-link " +
                        (currentPage === "/" ||
                        currentPage.includes("/dogs") ||
                        currentPage.includes("/dog")
                          ? "active"
                          : "")
                      }
                      onClick={() => this.setActiveLink("/dogs")}
                    >
                      {t("Все собаки")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/favoriteDogs"
                      className={
                        "nav-link " +
                        (currentPage.includes("/favoriteDogs") ||
                        currentPage.includes("/favoriteDogs")
                          ? "active"
                          : "")
                      }
                      onClick={() => this.setActiveLink("/favoriteDogs")}
                    >
                      {t("Избранное")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/mydogs"
                      className={
                        "nav-link " +
                        (currentPage.includes("/mydogs") ||
                        currentPage.includes("/myDog")
                          ? "active"
                          : "")
                      }
                      onClick={() => this.setActiveLink("/mydogs")}
                    >
                      {t("Мои собаки")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/handlers"
                      className={
                        "nav-link " +
                        (currentPage.includes("/handler") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/handlers")}
                    >
                      {t("Найти хендлера")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/kennels"
                      className={
                        "nav-link " +
                        (currentPage.includes("/kennel") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/kennels")}
                    >
                      {t("Питомники")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/breedingdogs"
                      className={
                        "nav-link " +
                        (currentPage.includes("/breedingdogs") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/breedingdogs")}
                    >
                      {t("Вязки")}
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/chats"
                      className={
                        "nav-link " +
                        (currentPage.includes("/chat") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/chats")}
                      connection={this.connection}
                    >
                      {t("Чат")}
                      <span
                        id="unreadMessagesContainer"
                        class="badge badge-light ml-1 "
                        style={
                          this.state.numberOfUnreadMessages > 0
                            ? { display: "inline-block" }
                            : { display: "none" }
                        }
                      >
                        {this.state.numberOfUnreadMessages}
                      </span>
                    </Link>
                  </li>
                  {/*<li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    to="/friendforwalk"
                    className={
                      "nav-link " +
                      (currentPage.includes("/friendforwalk") ? "active" : "")
                    }
                    onClick={() => this.setActiveLink("/friendforwalk")}
                  >
                    {t("Кто гуляет рядом")}
                  </Link>
                </li>*/}
                </ul>
              </div>
              <div class="navbar-collapse collapse w-60 order-3 dual-collapse2">
                <ul class="navbar-nav ml-auto">
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/cabinet"
                      className={
                        "nav-link " +
                        (currentPage.includes("/cabinet") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/cabinet")}
                    >
                      {t("Кабинет")}
                    </Link>
                  </li>

                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link
                      to="/logout"
                      className={
                        "nav-link " +
                        (currentPage.includes("/logout") ? "active" : "")
                      }
                      onClick={() => this.setActiveLink("/logout")}
                    >
                      {t("Выход")}
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          )}

          <div>
            {/* <Route path="/" exact component={Landing} /> */}
            <Route
              path="/dogs"
              render={(props) => (
                <Dogs onlyDogsAvailableForBreeding={false} {...props} />
              )}
            />
            <Route path="/favoriteDogs" component={FavoriteDogs} />
            <Route path="/dog/:id" component={Dog} />
            {/* <Route path="/landing" component={Landing} /> */}
            <Route path="/myDogs" component={MyDogs} />
            <Route path="/myDog/:id" component={MyDog} />
            <Route path="/handlers" component={Handlers} />
            <Route path="/handler/:id" component={Handler} />
            <Route path="/kennels" component={Kennels} />
            <Route path="/kennel/:id" component={Kennel} />
            <Route
              path="/breedingdogs"
              render={(props) => (
                <Dogs onlyDogsAvailableForBreeding={true} {...props} />
              )}
            />
            <Route
              path="/chats"
              render={(props) => (
                <Chats
                  connection={this.connection}
                  decreaseCounter={this.decreaseMessageCounter}
                  setMessageCounter={this.setMessageCounter}
                  getMessageCounter={this.getMessageCounter}
                  {...props}
                />
              )}
            />
            <Route
              path="/chat/:id?"
              render={(props) => (
                <Chat
                  connection={this.connection}
                  decreaseCounter={this.decreaseMessageCounter}
                  setMessageCounter={this.setMessageCounter}
                  getMessageCounter={this.getMessageCounter}
                  {...props}
                />
              )}
            />
            <Route path="/friendforwalk" component={GoogleApiWrapper} />
            <Route path="/cabinet" component={Cabinet} />
            <Route path="/user/confirm/:id" component={UserConfirm} />
            <Route path="/user/confirmNotice" component={UserConfirmNotice} />
            <Route path="/logout" component={LogOut} />
            <Route path="/agreement" component={Agreement} />
            <Route path="/facebookPage" component={FacebookPage} />
          </div>
        </Router>
      </div>
    );
  }
}

export default withTranslation()(App);
