import React from "react";
import AuthComponent from "../../../authComponent";
import HealthTable from './healthTable';
import { withTranslation } from 'react-i18next';

class VetActions extends AuthComponent {

  render() {
    const { dogId, t } = this.props;

    return (
      <div id="vetActions" class="container">
        <ul class="nav nav-pills mb-3" id="vetactions-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="vaccination-rabies-tab"
              data-toggle="pill"
              href="#vaccinationRabies"
              role="tab"
              aria-selected="true"
            >
              {t("Вакцинация против бешенства")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="vaccination-others-tab"
              data-toggle="pill"
              href="#vaccinationOthers"
              role="tab"
              aria-selected="false"
            >
              {t("Другие вакцинации")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="deworming-tab"
              data-toggle="pill"
              href="#deworming"
              role="tab"
              aria-selected="false"
            >
              {t("Дегельминтизация")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="tick-treatment-tab"
              data-toggle="pill"
              href="#tick-treatment"
              role="tab"
              aria-selected="false"
            >
              {t("Обработки от клещей")}
            </a>
          </li>
        </ul>
        <div class="tab-content" id="tab-content">
          <div
            class="tab-pane fade show active"
            id="vaccinationRabies"
            role="tabpanel"
            aria-labelledby="vaccination-rabies-tab"
          >
            <HealthTable dogId={dogId} addButtonName={t("Отметить вакцинацию")} medicineType="VaccinationRabies"></HealthTable>
          </div>
          <div
            class="tab-pane fade"
            id="vaccinationOthers"
            role="tabpanel"
            aria-labelledby="vaccination-others-tab"
          >
            <HealthTable dogId={dogId} addButtonName={t("Отметить вакцинацию")} medicineType="VaccinationOthers"></HealthTable>
          </div>
          <div
            class="tab-pane fade"
            id="deworming"
            role="tabpanel"
            aria-labelledby="deworming-tab"
          >
            <HealthTable dogId={dogId} addButtonName={t("Отметить дегельмитезацию")} medicineType="Deworming"></HealthTable>
          </div>
          <div
            class="tab-pane fade"
            id="tick-treatment"
            role="tabpanel"
            aria-labelledby="tick-treatment-tab"
          >
            <HealthTable dogId={dogId} addButtonName={t("Отметить обработку от клещей")} medicineType="TickTreatment"></HealthTable>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VetActions)