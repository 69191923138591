import moment from "moment";
import Cookies from "js-cookie";

export let dateFormat = null;
export function convertToDate(dateString, dateFormat) {
  if (dateString == null || dateString === "") {
    return null;
  }

  if (dateFormat == null) {
    dateFormat = Cookies.get("date_format");
  }

  let result = moment(dateString, dateFormat).toDate();
  if (result.toString() === "Invalid Date") {
    result = moment(dateString, "DD.MM.YYYY").toDate();
  }

  return result;
}


