import React from "react";
import AuthComponent from "../../authComponent";
import { Link } from "react-router-dom";
import "./chat.css";
import { imageContainerUrl } from "../../apiServer";
import DeleteButton from "../../components/deleteButton";
import { withTranslation } from 'react-i18next';

class Chat extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      chats: []
    };
  }

  getChats = () => {
    this.ajaxCall("Chat/GetChats", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        chats: response
      });
    });
  };

  componentDidMount() {
    this.getChats();

    this.props.connection.on("receiveMessage", this.receivedMessageEvent);
  }

  deleteChat = id => {
    this.ajaxCall("Chat/DeleteChat", {
      method: "post",
      headers: this.authHeaders,
      body: id
    }).then(response => {
      this.setState({
        chats: this.state.chats.filter(a => a.chatId !== id)
      });
    });
  };

  receivedMessageEvent = (message, messageFromUserId) => {
    if (
      this.state.chats.filter(chat => chat.userId === messageFromUserId) == null
    ) {
      this.getChats();
    } else {
      this.setState({
        chats: this.state.chats.map(chat => {
          if (chat.userId === messageFromUserId) {
            chat.unreadMessages++;
          }
          return chat;
        })
      });
    }
  };

  render() {
    const { t } = this.props;

    const { chats } = this.state;

    return (
      <div className="chats container">
        {chats && chats.length === 0 && (
          <div>{t("Пока у Вас нет новых сообщений")}</div>
        )}
        <div>
          {chats &&
            chats.map(chat => (
              <div className="chat">
                <Link to={"/chat/" + chat.chatId} connection={this.connection}>
                  <img
                    src={imageContainerUrl + "userphotos/" + chat.userPhoto}
                    class="card-img mb-3"
                    alt=""
                    onError={e =>
                      (e.target.src =
                        imageContainerUrl + "common/userplaceholder.svg")
                    }
                  ></img>
                  {chat.userName == null ? "(Имя не указано)" : chat.userName}
                  {chat.unreadMessages > 0 && (
                    <span class="badge badge-light ml-1">
                      {chat.unreadMessages}
                    </span>
                  )}
                </Link>
                <DeleteButton
                  onClick={() => this.deleteChat(chat.chatId)}
                  id={chat.chatId}
                  entity="chat"
                  confirmationText={t("Вы действительно хотите удалить данный диалог? В таком случае, чат удалится и у второго участника данной беседы.")}
                ></DeleteButton>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Chat)