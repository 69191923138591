import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import { Link } from "react-router-dom";
import "./kennels.css";
import DiscussionButton from "../../components/discussionButton";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class Kennels extends NonAuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      kennels: [],
      organizations: []
    };
  }

  getKennelOrganizations = () => {
    this.ajaxCall("DogPublic/GetKennelOrganizations", {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        organizations: response
      });

      this.getKennels();
    });
  };

  getKennels = () => {
    this.ajaxCall("KennelPublic/GetKennels", {
      method: "get",
      headers: this.nonAuthHeaders
    }).then(response => {
      this.setState({
        kennels: response
      });
    });
  };

  discuss = userId => {
    this.ajaxCall("Chat/StartChatWithHandler", {
      method: "post",
      headers: this.authHeaders,
      body: userId.toString()
    }).then(response => {
      window.location.href = "/chat/" + response.chatId;
    });
  };

  componentDidMount() {
    this.getKennelOrganizations();
  }

  render() {
    const { t } = this.props;

    return (
      <div id="kennels" className="container">
        <Helmet>
          <title>Питомники собак</title>
          <meta name="description" content="На нашем портале TERRA-DOG представлены большое количество Питомников собак с фото, отзывами и собаками которые у питомников есть в наличии"></meta>
        </Helmet>
        <div class="one">
          <h1 class="one">
              Питомники собак
          </h1>
        </div>
        <div className="kennelContainer">
          {this.state.kennels &&
            this.state.kennels.map(kennel => (
              <div className="card customCard mr-3 mb-3" key={kennel.id}>
                {this.currentUserId && (
                  <div className="actionButtons">
                    <DiscussionButton
                      onClick={() => {
                        this.discuss(kennel.userId);
                      }}
                    ></DiscussionButton>
                  </div>
                )}

                <img
                  src={this.imageUrl + "userphotos/" + kennel.photo}
                  className="card-img"
                  alt={"Питомник собак " + kennel.name}
                  onError={e =>
                    (e.target.src =
                      this.imageUrl + "common/userplaceholder.svg")
                  }
                ></img>
                <div className="card-body">
                  <h5 className="card-title">
                    <Link className={"nav-link"} to={"/kennel/" + kennel.id}>
                      {kennel.name}
                    </Link>
                  </h5>
                  <p className="card-text mb-0 pb-0">
                    {t("Организация")}:{" "}
                    {
                      this.state.organizations.filter(
                        a => a.id === kennel.kennelOrganizationId
                      )[0].name
                    }
                  </p>
                  <p className="card-text mb-0 pb-0">
                    <small className="text-muted">
                      {t("Породы")}:{" "}
                      {kennel.breedsString != null
                        ? kennel.breedsString.length > 50
                          ? kennel.breedsString.substr(0, 47) + "..."
                          : kennel.breedsString
                        : ""}
                    </small>
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Kennels)