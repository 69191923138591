import React from "react";
import AuthComponent from "../../../authComponent";
import { handleInputChange } from "../../../inputUtilities";
import Octicon, { FileSymlinkFile, Trashcan } from "@primer/octicons-react";
import { PuppyStatus } from "../../../enums";
import { withTranslation } from 'react-i18next';

class Puppy extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      puppyId: this.props.puppyId,
      dogBreedingId: this.props.dogBreedingId,
      genderId: null,
      name: "",
      status: null,
      color: "",
      typeOfCoat: "",
      price: "",
      notes: "",
      dogPhotos: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  deletePuppyPhoto = photoName => {
    this.ajaxCall("Image/DeleteImage/PuppyPhotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        dogPhotos: this.state.dogPhotos.filter(a => a !== response.imageName)
      });
    });
  };

  rotatePuppyPhoto = photoName => {
    this.ajaxCall("Image/RotateImage/PuppyPhotos/" + photoName, {
      method: "post",
      headers: this.authHeaders
    }).then(() => {
      const hashResult = new Date().getTime();
      this.setState({
        imageHash: hashResult
      });
    });
  };

  uploadPuppyPhoto = event => {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    var formData = new FormData();
    formData.append(`photo`, file);

    this.ajaxCall(
      "Image/UploadImage/PuppyPhotos/" +
        (this.state.dogId == null ? "0" : this.state.dogId),
      {
        method: "post",
        headers: this.authHeadersForFiles,
        body: formData
      }
    ).then(response => {
      let dogPhotos = this.state.dogPhotos;
      if (dogPhotos == null) {
        dogPhotos = [];
      }

      dogPhotos.push(response.fileName);

      this.setState({
        dogPhotos: dogPhotos
      });
    });
  };

  getPuppy = () => {
    if (this.props.puppyId === 0) {
      this.setState({
        genderId: null,
        name: "",
        status: null,
        color: "",
        typeOfCoat: "",
        price: "",
        notes: "",
        dogPhotos: []
      });
    } else {
      this.ajaxCall("Dog/GetPuppy/" + this.props.puppyId, {
        method: "get",
        headers: this.authHeaders
      }).then(puppy => {
        this.setState({
          genderId: puppy.genderId,
          name: puppy.name,
          status: puppy.status,
          color: puppy.color,
          typeOfCoat: puppy.typeOfCoat,
          price: puppy.price,
          notes: puppy.notes,
          dogPhotos: puppy.photos
        });
      });
    }
  };

  savePuppy = () => {
    const bodyJson = {
      id: this.state.puppyId == null ? 0 : this.state.puppyId,
      dogBreedingId: this.props.dogBreedingId,
      genderId: this.state.genderId,
      name: this.state.name,
      status: this.state.status,
      color: this.state.color,
      typeOfCoat: this.state.typeOfCoat,
      price: this.state.price,
      notes: this.state.notes,
      photos: this.state.dogPhotos
    };

    this.ajaxCall("Dog/SavePuppy", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(response => {
      this.props.refreshPuppies();
      this.props.cancelEditingPuppy();
    });
  };

  componentDidMount() {
    this.getPuppy();
  }

  render() {
    const { t } = this.props;

    const {
      genderId,
      name,
      status,
      color,
      typeOfCoat,
      price,
      notes,
      dogPhotos
    } = this.state;

    const savingDisabled =
      genderId == null ||
      name == null ||
      name === "" ||
      status == null;

    return (
      <div id="puppy">
        <div class="puppyPhotos">
          {dogPhotos &&
            dogPhotos.map((dogPhoto, idx) => (
              <div className="card customCard mr-3 mb-3 puppyPhoto">
                <div class="photoActions">
                  <span
                    title="Перевернуть фото"
                    class="clickableIcon mr-2"
                    onClick={() => this.rotatePuppyPhoto(dogPhoto)}
                  >
                    <Octicon icon={FileSymlinkFile} size="medium" />
                  </span>
                  <span
                    title="Удалить фото"
                    class="clickableIcon mr-2"
                    onClick={() => this.deletePuppyPhoto(dogPhoto)}
                  >
                    <Octicon icon={Trashcan} size="medium" />
                  </span>
                </div>
                <img
                  src={
                    this.imageUrl +
                    "puppyphotos/" +
                    dogPhoto +
                    "?" +
                    this.state.imageHash
                  }
                  className="card-img puppyPhoto mr-2 mb-2"
                  alt=""
                ></img>
              </div>
            ))}
          {(dogPhotos == null || dogPhotos.length < 3) && (
            <label
              for="puppyPhoto"
              className="card customCard mr-3 mb-3 addImageButton"
            >
              <img src={this.dogPlaceHolder} alt="..."></img>
              <div className="card-body">
                <h5 className="card-title">{t("Добавить фото")}...</h5>
              </div>
              <input
                type="file"
                class="form-control-file"
                id="puppyPhoto"
                name="puppyPhoto"
                style={{ visibility: "hidden" }}
                onChange={this.uploadPuppyPhoto}
              ></input>
            </label>
          )}
        </div>

        <div className="col-sm-6 pl-0 mt-3" style={{ clear: "both" }}>
          <div class="form-group">
            <label for="genderId">{t("Пол")}</label>
            <select
              class="form-control"
              id="genderId"
              name="genderId"
              value={genderId}
              onChange={this.handleInputChange}
            >
              <option value=""></option>
              <option value="1">{t("Кобель")}</option>
              <option value="2">{t("Сука")}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">{t("Кличка")}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              autocomplete="off"
              value={name}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div class="form-group">
            <label for="status">{t("Статус")}</label>
            <select
              class="form-control"
              id="status"
              name="status"
              value={status}
              onChange={this.handleInputChange}
            >
              <option value=""></option>
              <option value="1">{PuppyStatus.notForSale}</option>
              <option value="2">{PuppyStatus.forSale}</option>
              <option value="3">{PuppyStatus.sold}</option>
              <option value="4">{PuppyStatus.reserved}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="color">{t("Окрас")}</label>
            <input
              type="text"
              class="form-control"
              id="color"
              name="color"
              autocomplete="off"
              value={color}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div class="form-group">
            <label for="typeOfCoat">{t("Тип шерсти")}</label>
            <input
              type="text"
              class="form-control"
              id="typeOfCoat"
              name="typeOfCoat"
              autocomplete="off"
              value={typeOfCoat}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div class="form-group">
            <label for="price">{t("Цена")}</label>
            <input
              type="text"
              class="form-control"
              id="price"
              name="price"
              autocomplete="off"
              value={price}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div class="form-group">
            <label for="notes">{t("О щенке")}</label>
            <textarea
              class="form-control"
              id="notes"
              name="notes"
              value={notes}
              onChange={this.handleInputChange}
              rows="5"
              maxLength="2000"
            ></textarea>
          </div>
        </div>

        <div className="actionButtons mb-3">
          <button
            type="button"
            onClick={this.savePuppy}
            disabled={savingDisabled}
            className="btn btn-primary mt-3 mr-2"
          >
            {t("Сохранить")}
          </button>

          <button
            type="button"
            onClick={this.props.cancelEditingPuppy}
            className="btn btn-secondary mt-3 mr-2"
          >
            {t("Отмена")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Puppy)