// NOTE: Don't forget to bind it before using: this.handleInputChange = this.handleInputChange.bind(this);
export function handleInputChange(event, callBack) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    if (callBack != null) {
      callBack();
    }
  }