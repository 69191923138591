import React, { Component } from "react";
import { handleInputChange } from "../../../inputUtilities";
import { getCurrentUserId } from "../../../authentification";
import Select from "react-select";
import Octicon, { FileSymlinkFile, Trashcan } from "@primer/octicons-react";
import {
  ajaxCaller,
  authHeaders,
  authHeadersForFiles
} from "../../../ajaxCaller";
import { imageContainerUrl } from "../../../apiServer";
import { withTranslation } from 'react-i18next';

class UserMainInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ownPhoto: "",
      firstTimeLoading: true,
      name: null,
      phone: null,
      city: null,
      imageHash: 0,
      isHandler: false,
      isKennel: false,

      breeds: [],
      organizations: [],
      
      workCost: null,
      currencyId: 1,
      allBreeds: false,
      selectedBreeds: [],
      aboutHandler: "",
      
      kennelId: 0,
      kennelName: '',
      webSiteUrl: '',
      kennelOrganizationId: null,
      selectedKennelBreeds: [],
      aboutKennel: ''
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  uploadOwnPhoto = event => {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    var formData = new FormData();
    formData.append(`photo`, file);

    ajaxCaller("Image/UploadImage/UserPhotos/0", {
      method: "post",
      headers: authHeadersForFiles,
      body: formData
    }).then(response => {
      this.setState({
        ownPhoto: response.fileName
      });
    });
  };

  deleteUserPhoto = photoName => {
    ajaxCaller("Image/DeleteImage/userphotos/" + photoName, {
      method: "post",
      headers: authHeaders
    }).then(response => {
      this.setState({
        ownPhoto: ""
      });
    });
  };

  rotateUserPhoto = photoName => {
    ajaxCaller("Image/RotateImage/userphotos/" + photoName, {
      method: "post",
      headers: authHeaders
    }).then(() => {
      const hashResult = new Date().getTime();
      this.setState({
        imageHash: hashResult
      });
    });
  };

  updateUserMainInfo = () => {
    ajaxCaller("User/UpdateUserMainData", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify({
        id: getCurrentUserId(),
        name: this.state.name,
        phone: this.state.phone,
        city: this.state.city,
        isHandler: this.state.isHandler,
        isKennel: this.state.isKennel,
      })
    }).then(response => {
      if (!response.isError) {
        this.props.successSaving();

        if (this.state.isHandler === false && this.state.isKennel === false) {
          window.location.href = "/myDog/0";
        }

        if (this.state.isHandler) {
          this.props.showHandlerArea();
        } else {
          this.props.hideHandlerArea();
        }

        if (this.state.isKennel) {
          this.props.showKennelArea();
        } else {
          this.props.hideKennelArea();
        }
      }
    });
  };

  getUserMainInfo = () => {
    ajaxCaller("User/GetUserMainData", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        firstTimeLoading: false,
        name: response.name,
        phone: response.phone,
        city: response.city,
        ownPhoto: response.photo,
        isHandler: response.isHandler,
        isKennel: response.isKennel
      });

      if (response.isHandler) {
        this.props.showHandlerArea();
      } else {
        this.props.hideHandlerArea();
      }

      if (response.isKennel) {
        this.props.showKennelArea();
      } else {
        this.props.hideKennelArea();
      }
    });
  };

  handleBreedChange = options => {
    this.setState({ selectedBreeds: options });
  };

  getAllBreeds = () => {
    ajaxCaller("BreedPublic/GetAllBreeds", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.getHandlerInfo();
      this.getKennelInfo();
      this.setState({
        breeds: response
      });
    });
  };

  getCurrencies = () => {
    ajaxCaller("Currency/GetCurrencies", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        currencies: response
      });
    });
  };

  getHandlerInfo = () => {
    ajaxCaller("Handler/GetHandler", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        handlerPhoto: response.photo,
        workCost: response.cost,
        currencyId: response.currencyId,
        allBreeds: response.allBreeds,
        aboutHandler: response.aboutMe,
        selectedBreeds:
          response.breedIds != null
            ? this.state.breeds
                .map(a => {
                  return {
                    label: a.label,
                    id: a.id,
                    value: a.id,
                    name: null,
                    selected: response.breedIds.includes(a.id)
                  };
                })
                .filter(a => a.selected === true)
            : []
      });
    });
  };

  updateHandlerInfo = () => {
    if (this.state.isHandler) {
      const bodyJson = {
        id: 0,
        userId: getCurrentUserId(),
        cost: this.state.workCost,
        currencyId: this.state.currencyId,
        aboutMe: this.state.aboutHandler,
        allBreeds: this.state.allBreeds,
        breedIds:
          this.state.selectedBreeds == null
            ? []
            : this.state.selectedBreeds.map(a => a.id)
      };
  
      ajaxCaller("Handler/UpdateHandler", {
        method: "post",
        headers: authHeaders,
        body: JSON.stringify(bodyJson)
      }).then(response => {
        if (!response.isError) {
          this.props.successSaving();
        }
      });
    }
  };

  getKennelOrganizations = () => {
    ajaxCaller("DogPublic/GetKennelOrganizations", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        organizations: response
      });
    });
  };

  handleKennelBreedChange = options => {
    this.setState({ selectedKennelBreeds: options });
  };

  getKennelInfo = () => {
    ajaxCaller("Kennel/GetMyKennel", {
      method: "get",
      headers: authHeaders
    }).then(response => {
      this.setState({
        kennelId: response.id,
        kennelName: response.name,
        webSiteUrl: response.webSiteUrl,
        kennelOrganizationId: response.kennelOrganizationId,
        aboutKennel: response.aboutKennel,
        selectedKennelBreeds:
          response.breeds != null
            ? this.state.breeds
                .map(a => {
                  return {
                    label: a.label,
                    id: a.id,
                    value: a.id,
                    name: null,
                    selected: response.breeds.filter(b => b.id === a.id).length === 1
                  };
                })
                .filter(a => a.selected === true)
            : []
      });
    });
  };

  updateKennelInfo = () => {
    if (this.state.isKennel) {
      const bodyJson = {
        id: this.state.kennelId,
        userId: getCurrentUserId(),
        name: this.state.kennelName,
        webSiteUrl: this.state.webSiteUrl,
        kennelOrganizationId: this.state.kennelOrganizationId,
        aboutKennel: this.state.aboutKennel,
        breeds:
          this.state.selectedKennelBreeds == null
            ? []
            : this.state.selectedKennelBreeds.map(a => ({ id: a.id, name: '' }))
      };
  
      ajaxCaller("Kennel/SaveKennel", {
        method: "post",
        headers: authHeaders,
        body: JSON.stringify(bodyJson)
      }).then(response => {
        if (!response.isError) {
          this.props.successSaving();
        }
      });
    }
  };

  componentDidMount() {
    this.getUserMainInfo();
    this.getCurrencies();
    this.getAllBreeds();
    this.getKennelOrganizations();
  }

  render() {
    const { t } = this.props;

    const checkHandler =
      this.state.isHandler === false ||
      (this.state.workCost != null &&
        this.state.workCost !== "" &&
        (this.state.allBreeds ||
          (this.state.selectedBreeds != null &&
            this.state.selectedBreeds.length > 0)));

    const checkKennel =
      this.state.isKennel === false ||
      (this.state.kennelName !== "" &&
        this.state.kennelName != null &&
        this.state.kennelOrganizationId !== "" &&
        this.state.kennelOrganizationId != null &&
        this.state.aboutKennel !== "" &&
        this.state.aboutKennel != null &&
        this.state.webSiteUrl !== "" &&
        this.state.webSiteUrl != null &&
        this.state.selectedKennelBreeds != null &&
        this.state.selectedKennelBreeds.length > 0);

    const updateMainUserInfoDisabled =
      this.state.name == null ||
      this.state.name === "" ||
      checkHandler === false ||
      checkKennel === false;

    return (
      <>
        <form class="row">
          <div class="col-sm-4">
            <div className="photoContainer">
              <div class="photoActions">
                <span
                  title={t("Перевернуть фото")}
                  class="clickableIcon mr-2"
                  onClick={() => this.rotateUserPhoto(this.state.ownPhoto)}
                >
                  <Octicon icon={FileSymlinkFile} size="medium" />
                </span>
                <span
                  title={t("Удалить фото")}
                  class="clickableIcon mr-2"
                  onClick={() => this.deleteUserPhoto(this.state.ownPhoto)}
                >
                  <Octicon icon={Trashcan} size="medium" />
                </span>
              </div>
              <img
                src={
                  imageContainerUrl +
                  "userphotos/" +
                  this.state.ownPhoto +
                  "?" +
                  this.state.imageHash
                }
                class="card-img mb-3"
                alt=""
                onError={e =>
                  (e.target.src =
                    imageContainerUrl + "common/userplaceholder.svg")
                }
              ></img>
            </div>

            <div class="form-group">
              <button type="button" class="btn btn-primary uploadButton">
                <label for="myPhoto">{t("Выбрать фото")}</label>
              </button>
              <input
                type="file"
                class="form-control-file"
                id="myPhoto"
                onChange={this.uploadOwnPhoto}
                style={{ visibility: "hidden" }}
              ></input>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <label for="name">{t("Имя и Фамилия")}</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
              ></input>
            </div>
            <div class="form-group">
              <label for="phone">{t("Телефон")}</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                value={this.state.phone}
                onChange={this.handleInputChange}
              ></input>
            </div>
            <div class="form-group">
              <label for="city">{t("Город")}</label>
              <input
                type="text"
                class="form-control"
                id="city"
                name="city"
                value={this.state.city}
                onChange={this.handleInputChange}
              ></input>
            </div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isHandler"
                name="isHandler"
                checked={this.state.isHandler}
                value={this.state.isHandler}
                onChange={this.handleInputChange}
              ></input>
              <label class="form-check-label" for="isHandler">
                {t("Я хендлер")}
              </label>
            </div>
            {this.state.isHandler && (
              <>
                <div class="form-group">
                  <label for="workCost">{t("Стоимость показа")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="workCost"
                    name="workCost"
                    value={this.state.workCost}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div class="form-group">
                  <label for="currencyId">{t("Валюта")}</label>
                  <select
                    class="form-control"
                    id="currencyId"
                    name="currencyId"
                    value={this.state.currencyId}
                    onChange={this.handleInputChange}
                  >
                    {this.state.currencies &&
                      this.state.currencies.map(currency => (
                        <option value={currency.id}>{currency.name}</option>
                      ))}
                  </select>
                </div>
                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="allBreeds"
                    name="allBreeds"
                    checked={this.state.allBreeds}
                    value={this.state.allBreeds}
                    onChange={this.handleInputChange}
                  ></input>
                  <label class="form-check-label" for="allBreeds">
                    {t("Выставляю все породы")}
                  </label>
                </div>
                {this.state.allBreeds === false && (
                  <div class="form-group">
                    <label for="breeds">{t("Породы")}</label>
                    <Select
                      id="breeds"
                      value={this.state.selectedBreeds}
                      onChange={this.handleBreedChange}
                      options={this.state.breeds}
                      isMulti={true}
                    />
                  </div>
                )}
                <div class="form-group">
                  <label for="aboutHandler">{t("О себе")}</label>
                  <textarea
                    class="form-control"
                    id="aboutHandler"
                    name="aboutHandler"
                    value={this.state.aboutHandler}
                    onChange={this.handleInputChange}
                    rows="5"
                    maxLength="2000"
                  ></textarea>
                </div>
              </>
            )}

            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isKennel"
                name="isKennel"
                checked={this.state.isKennel}
                value={this.state.isKennel}
                onChange={this.handleInputChange}
              ></input>
              <label class="form-check-label" for="isKennel">
                {t("Я владелец питомника")}
              </label>
            </div>

            {this.state.isKennel && (
              <>
                <div class="form-group">
                  <label for="kennelName">{t("Название питомника")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="kennelName"
                    name="kennelName"
                    value={this.state.kennelName}
                    onChange={this.handleInputChange}
                  ></input>
                </div>

                <div class="form-group">
                  <label for="webSiteUrl">{t("Сайт питомника")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="webSiteUrl"
                    name="webSiteUrl"
                    value={this.state.webSiteUrl}
                    onChange={this.handleInputChange}
                  ></input>
                </div>

                <div class="form-group">
                  <label for="kennelOrganizationId">
                    {t("Кинологическая организация")}
                  </label>
                  <select
                    class="form-control"
                    id="kennelOrganizationId"
                    name="kennelOrganizationId"
                    value={this.state.kennelOrganizationId}
                    onChange={this.handleInputChange}
                  >
                    <option value=""></option>
                    {this.state.organizations &&
                      this.state.organizations.map(a => (
                        <option value={a.id}>{a.name}</option>
                      ))}
                  </select>
                </div>

                <div class="form-group">
                  <label for="kennelBreeds">{t("Породы")}</label>
                  <Select
                    id="kennelBreeds"
                    value={this.state.selectedKennelBreeds}
                    onChange={this.handleKennelBreedChange}
                    options={this.state.breeds}
                    isMulti={true}
                  />
                </div>

                <div class="form-group">
                  <label for="aboutKennel">{t("О питомнике")}</label>
                  <textarea
                    class="form-control"
                    id="aboutKennel"
                    name="aboutKennel"
                    value={this.state.aboutKennel}
                    onChange={this.handleInputChange}
                    rows="5"
                    maxLength="2000"
                  ></textarea>
                </div>
              </>
            )}

            <button
              type="button"
              class="btn btn-primary"
              disabled={updateMainUserInfoDisabled}
              onClick={() => {
                this.updateUserMainInfo();
                this.updateHandlerInfo();
                this.updateKennelInfo();
              }}
            >
              {t("Сохранить")}
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default withTranslation()(UserMainInfo)