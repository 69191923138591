import React from "react";
import { handleInputChange } from "../../inputUtilities";
import { ajaxCaller } from "../../ajaxCaller";
import NonAuthComponent from "../../nonAuthComponent";
import { withTranslation } from 'react-i18next';

class HandlerEventDetails extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      handlerAvailabilityId: 0,
      dogClassId: null,
      genderId: null,
      availabilityId: null,
      dogName: "",
      availabilities: []
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  getHandlerAvailabilities = () => {
    ajaxCaller(
      "HandlerPublic/GetHanderAvailabilities/" + this.props.handlerEventBreedId,
      {
        method: "get",
        headers: this.nonAuthHeaders
      }
    ).then(response => {
      this.setState({
        availabilities: response
      });
    });
  };

  componentDidMount() {
    this.getHandlerAvailabilities();
  }

  render() {
    const { t } = this.props;

    const availabilities = this.state.availabilities;

    return (
      <div>
        <table className="handlerEventDetails" class="table table-bordered">
          <thead class="bg-primary">
            <tr class="table-primary">
              <th scope="col">{t("Класс")}</th>
              <th scope="col">{t("Кобель")}</th>
              <th scope="col">{t("Сука")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.dogClasses &&
              this.props.dogClasses.map(dogClass => {
                const male = availabilities.filter(
                  availability =>
                    availability.dogClassId === dogClass.id &&
                    availability.genderId === 1
                );
                const female = availabilities.filter(
                  availability =>
                    availability.dogClassId === dogClass.id &&
                    availability.genderId === 2
                );

                return (
                  <tr>
                    <th scope="row">{dogClass.name}</th>
                    <td
                      data-toggle="modal"
                      data-target={
                        "#availabilityModal" + this.props.handlerEventBreedId
                      }
                      class={
                        (male.length === 1
                          ? "table-secondary"
                          : "table-success")
                      }
                    >
                    </td>
                    <td
                      data-toggle="modal"
                      data-target={
                        "#availabilityModal" + this.props.handlerEventBreedId
                      }
                      class={
                        (female.length === 1
                          ? "table-secondary"
                          : "table-success")
                      }
                    >
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(HandlerEventDetails)