import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import LitterAnnouncement from './litterAnnouncement';

export default class LitterAnnouncements extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = {
        litters: []
    };
  }  

  getLitterAnnouncements = () => {
    this.ajaxCall("KennelPublic/GetLittersAnnouncements/" + this.props.kennelId, {
        method: "get",
        headers: this.authHeaders
      }).then(litters => {
        this.setState({
            litters
        });
      });
  };  

  componentDidMount() {
    this.getLitterAnnouncements();
  }

  render() {
    const {
        litters
    } = this.state;

    return (
      <div id="litterAnnouncements">
        {litters && litters.map(litter => <LitterAnnouncement key={"dogLitterAnnouncement" + litter.id} litter={litter}></LitterAnnouncement>)}
      </div>
    );
  }
}
