import Cookies from "js-cookie";

export const getCurrentUserId = () => {
  if (Cookies.get("user_id") == null) {
    return null;
  } else {
    return parseInt(Cookies.get("user_id"));
  }
};

export const isAuthenticated = () => {
  const today = new Date();
  const loggedInDateString = Cookies.get("logged_in_date");

  if (loggedInDateString == null) {
    return false;
  }

  const loggedInDate = new Date(loggedInDateString);
  const hours = (today - loggedInDate) / 36e5;
  if (Cookies.get("access_token") != null && hours < 23) {
    return true;
  } else {
    return false;
  }
};

export const getAccessToken = () => {
  return Cookies.get("access_token");
}
