import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class UserConfirmNotice extends Component {
  render() {
    const { t } = this.props;

    return <div>{t("ConfirmEmailNotice")}</div>;
  }
}


export default withTranslation()(UserConfirmNotice)