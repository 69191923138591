import React, { Component } from "react";
import Octicon, { Pencil } from "@primer/octicons-react";
import { withTranslation } from 'react-i18next';

class EditButton extends Component {
  render() {
    const { onClick, t } = this.props;
    return (
        <span
          title={t("Редактировать")}
          class="clickableIcon mr-2"
          onClick={onClick}
        >
          <Octicon icon={Pencil} size="medium" />
        </span>
    );
  }
}

export default withTranslation()(EditButton)