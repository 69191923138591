import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const success = position => {
  //(position.coords.longitude + ", " + position.coords.latitude);
}

const fail = () => {
  ;
}

class FriendForWalk extends Component {
  saveCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, fail);
    } else {
      alert("Sorry, your browser does not support geolocation services.");
    }
  }

  render() {
    const mapStyles = {
      width: "100%",
      height: "100%"
    };
    return (
      <>
        <button
          type="button"
          class="btn btn-primary mb-3"
          onClick={this.saveCurrentPosition}
        >
          Разрешить другим пользователям видеть мою текущую гео-позицию
        </button>
        <Map
          google={this.props.google}
          zoom={18}
          style={mapStyles}
          initialCenter={{ lat: 50.327174, lng: 30.225395 }}
        >
          <Marker position={{ lat: 50.32729, lng: 30.225113 }} />
          <Marker position={{ lat: 50.326602, lng: 30.22415 }} />
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCHx-5yF63kfso2Wt9HTLrjqu_Y3WcirIg"
})(FriendForWalk);
