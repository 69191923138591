import React, { Component } from "react";
import Octicon, { Verified } from "@primer/octicons-react";
import { withTranslation } from 'react-i18next';

class FavoriteButton extends Component {
  state = {
    added: this.props.added
  }

  onClick = () => {
    this.setState({
      added: !this.state.added
    });
    this.props.onClick();
  }

  componentDidMount() {
    this.setState({
      added: this.props.added
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <span
          title={this.state.added ? t("Удалить из избранного") : t("Добавить в избранное")}
          class={"clickableIcon mr-2" + (!this.state.added ? " disabledIcon" : "")}
          onClick={this.onClick}
        >
          <Octicon icon={Verified} size="medium" />
        </span>
      </>
    );
  }
}

export default withTranslation()(FavoriteButton)
