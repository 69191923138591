import React, { Component } from "react";
import { Redirect } from "react-router";
import { ajaxNonJsonCaller } from '../../ajaxCaller';
import { withTranslation } from 'react-i18next';

class UserConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmedEmail: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    ajaxNonJsonCaller("User/Confirm/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
        this.setState({
          confirmedEmail: true
        });
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.confirmedEmail === true) {
      window.location.href = "/";
      return <Redirect to="/" />;
    } else {
      return <div>{t("Подождите пожалуйста, подтверждение...")}</div>;
    }
  }
}

export default withTranslation()(UserConfirm)