import React from "react";
import AuthComponent from "../../authComponent";
import { handleInputChange } from "../../inputUtilities";
import "./chat.css";
import { imageContainerUrl } from "../../apiServer";
import { withTranslation } from 'react-i18next';

class Chat extends AuthComponent {
  constructor(props) {
    super(props);

    let id = "0";
    if (this.props.match.params.id != null) {
      id = this.props.match.params.id;
    }

    this.state = {
      chatWithUserId: 0,
      chatWithUser: "",
      userPhoto: "",
      activeChatId: parseInt(id),
      chatItems: [],
      message: ""
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  handleEnterPress = target => {
    if (target.charCode === 13) {
      this.sendMessage();
    }
  };

  openChat = chatId => {
    this.ajaxCall("Chat/GetChatItems/" + chatId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        activeChatId: chatId,
        chatWithUserId: response.chatWithUserId,
        chatWithUser: response.chatWithUser,
        userPhoto: response.userPhoto,
        chatItems: response.items
      });

      this.scrollToBottom();
    });
  };

  sendMessage = () => {
    const bodyJson = {
      chatId: this.state.activeChatId,
      message: this.state.message
    };

    this.props.connection
      .invoke("SendMessage", this.state.chatWithUserId, this.state.message)
      .catch(err => console.error(err.toString()));

    this.setState({ message: "" });

    this.ajaxCall("Chat/SendNewMessage", {
      method: "post",
      headers: this.authHeaders,
      body: JSON.stringify(bodyJson)
    }).then(newChatItem => {
      const addArray = [];
      addArray.push(newChatItem);
      this.setState({
        message: "",
        chatItems: this.state.chatItems.concat(addArray)
      });
    });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.openChat(this.state.activeChatId);

    this.props.connection.on("receiveMessage", this.receivedMessageEvent);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  receivedMessageEvent = (message, messageFromUserId, timeStamp) => {
    const addArray = [];
    const newChatItem = {};
    newChatItem.message = message;
    newChatItem.timeStamp = timeStamp;
    addArray.push(newChatItem);
    const { chatWithUserId } = this.state;

    if (chatWithUserId === messageFromUserId) {
      this.props.decreaseCounter();

      this.setState({
        chatItems: this.state.chatItems.concat(addArray)
      });
    }
  };

  render() {
    const { t } = this.props;

    const { activeChatId, chatItems, chatWithUser, userPhoto } = this.state;

    return (
      <div className="chats container">
        <h5 className="mb-3 userInfo">
          {" "}
          <img
            src={imageContainerUrl + "userphotos/" + userPhoto}
            class="card-img mb-3"
            alt=""
            onError={e =>
              (e.target.src = imageContainerUrl + "common/userplaceholder.svg")
            }
          ></img>{" "}
          {chatWithUser == null ? t("(Имя не указано)") : chatWithUser}
        </h5>
        <div>
          <div class="tab-content" id="nav-tabContent">
            <div
              class={"tab-pane fade show active"}
              id={activeChatId}
              role="tabpanel"
              aria-labelledby={"list-chat"}
            >
              {chatItems &&
                chatItems.map(chatItem => (
                  <div
                    className="mb-4 chat-item"
                    style={
                      chatItem.userId === this.currentUserId
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    <i>{chatItem.timeStamp}</i><br />
                    <span
                      className={
                        chatItem.userId === this.currentUserId
                          ? "myMessage"
                          : "otherMessage"
                      }
                    >
                      {chatItem.message}
                    </span>
                  </div>
                ))}
              <div
                ref={el => {
                  this.messagesEnd = el;
                }}
              ></div>
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="message">{t("Введите сообщение")}</label>
            <textarea
              class="form-control"
              id="message"
              name="message"
              rows="3"
              value={this.state.message}
              onChange={this.handleInputChange}
              onKeyPress={this.handleEnterPress}
            ></textarea>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            onClick={this.sendMessage}
          >
            {t("Отправить")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Chat)