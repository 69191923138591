import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import ArrowLeftButton from "../../components/arrowLeftButton";
import ArrowRightButton from "../../components/arrowRightButton";
import LitterPuppy from "./litterPuppy";
import { withTranslation } from 'react-i18next';

class LitterAnnouncement extends NonAuthComponent {
  render() {
    const { litter, t } = this.props;

    return (
      <div className="litterAnnouncement mb-3 mt-3 ml-3 mr-3 pt-3 pl-3 pr-3 pb-3">
        <div className="dogsNames">
          <h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"/dog/" + litter.dogId}
            >
              {litter.dogName}
            </a>{" "}
            & {litter.matedDog}
          </h4>
        </div>

        <div
          id={"carousel" + litter.id}
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            {litter.photos &&
              litter.photos.length > 0 &&
              litter.photos.map(photo => (
                <li
                  data-target={"#carousel" + litter.id}
                  data-slide-to={litter.photos.indexOf(photo)}
                  class={litter.photos.indexOf(photo) === 0 ? "active" : ""}
                ></li>
              ))}
          </ol>
          <div class="carousel-inner">
            {litter.photos &&
              litter.photos.length > 0 &&
              litter.photos.map(photo => (
                <div
                  class={
                    "carousel-item" +
                    (litter.photos.indexOf(photo) === 0 ? " active" : "")
                  }
                >
                  <img
                    src={this.imageUrl + "dogbreedingphotos/" + photo}
                    class="center-block"
                    alt="..."
                  ></img>
                </div>
              ))}
          </div>

          {litter.photos && litter.photos.length > 1 && (
            <>
              <a
                class="carousel-control-prev"
                href={"#carousel" + litter.id}
                role="button"
                data-slide="prev"
              >
                <ArrowLeftButton></ArrowLeftButton>
              </a>
              <a
                class="carousel-control-next"
                href={"#carousel" + litter.id}
                role="button"
                data-slide="next"
              >
                <ArrowRightButton></ArrowRightButton>
              </a>
            </>
          )}
        </div>

        <table class="table table-striped mt-3">
          <colgroup>
            <col style={{ width: "30%" }}></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>{t("Дата родов")}</td>
              <td>{litter.puppiesBirthDate}</td>
            </tr>
            <tr>
              <td>{t("Литера")}</td>
              <td>{litter.litterName}</td>
            </tr>
            {litter.breedingInfo != null && litter.breedingInfo !== "" && (
              <tr>
                <td>{t("Информация о паре")}</td>
                <td>{litter.breedingInfo}</td>
              </tr>
            )}
          </tbody>
        </table>

        {litter.puppies.map(a => (
          <LitterPuppy puppy={a}></LitterPuppy>
        ))}
        <hr></hr>
      </div>
    );
  }
}

export default withTranslation()(LitterAnnouncement)