import React from "react";
import AuthComponent from "../../../authComponent";
import Breeding from "./breeding";
import EditButton from "../../../components/editButton";
import { withTranslation } from 'react-i18next';

class Breedings extends AuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      breedings: [],
      addEditBreeding: false,
      dogBreedingId: 0
    };
  }

  getDogBreedings = () => {
    this.ajaxCall("Dog/GetDogBreedings/" + this.props.dogId + "/false", {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        breedings: response
      });
    });
  };

  refreshBreedings = () => {
    this.getDogBreedings();
  }

  addEditDogBreeding = dogBreedingId => {
    this.setState({
      dogBreedingId,
      addEditBreeding: true
    });
  };

  cancelEditingDogBreeding = () => {
    this.setState({
      dogBreedingId: 0,
      addEditBreeding: false
    });
  };

  componentDidMount() {
    this.getDogBreedings();
  }

  render() {
    const { t } = this.props;

    const { breedings, dogBreedingId, addEditBreeding } = this.state;

    return addEditBreeding === false ? (
      <div>
        <div id="dogBreedings">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                {this.props.isMale === false && (
                  <th scope="col">{t("Начало течки")}</th>
                )}
                <th scope="col">{t("Первая вязка")}</th>
                <th scope="col">{t("Контрольная вязка")}</th>
                <th scope="col" title={t("Предполагаемая дата родов")}>
                  {t("ПДР")}
                </th>
                <th scope="col">
                  {this.props.isMale === true ? "Кличка суки" : "Кличка кобеля"}
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {breedings &&
                breedings.map(d => (
                  <tr key={d.id} onClick={() => this.addEditDogBreeding(d.id)}>
                    {this.props.isMale === false && <td>{d.heatStartDate}</td>}
                    <td>{d.firstBreedingDate}</td>
                    <td>{d.secondBreedingDate}</td>
                    <td>{d.estimatedСhildBirth}</td>
                    <td>{d.matedDog}</td>
                    <td style={{ textAlign: "right" }}>
                      <EditButton></EditButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={() => this.addEditDogBreeding(0)}
          className="btn btn-primary mt-3"
        >
          {t("Добавить вязку")}
        </button>
      </div>
    ) : (
      <div>
        <Breeding
          dogBreedingId={dogBreedingId}
          refreshBreedings={this.refreshBreedings}
          isMale={this.props.isMale}
          dogId={this.props.dogId}
          cancelEditingDogBreeding={this.cancelEditingDogBreeding}
          hasKennel={this.props.hasKennel}
        ></Breeding>
      </div>
    );
  }
}

export default withTranslation()(Breedings)