import React, { Component } from "react";

export default class Agreement extends Component {
  render() {
    return (
        <div className="container mt-3">
            <div style={{ textAlign: "center"}}>
                <img src="https://terradogstorage.blob.core.windows.net/common/logo.png" alt="TERRA-DOG" />
            </div>
            <h2>Пользовательское соглашение</h2>
            <p>
                Добро пожаловать на Terra-dog.com
                Настоящее Пользовательское положение регулирует использование вами сайта terra-dog.com и содержит информацию о сайте terra-dog.com (далее – Сайт), указанную ниже. Создавая аккаунт и используя сайт terra-dog.com, вы принимаете настоящие условия безусловно и в полном объеме.
                Администрация Сайта имеет право в любое время в одностороннем порядке изменять условия текущего Пользовательского соглашения. Такие изменения вступают в силу в течение 3 (трех) дней с момента размещения новой версии Пользовательского соглашения на Сайте. При несогласии Пользователя со внесенными изменениями, он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта. 
                Мы соглашаемся предоставлять вам сервисы и материалы сайта terra-dog.com. Сервис включает в себя все продукты, функции, материалы, сервисы, технологии и программное обеспечение сайта Terra-dog.com, которые мы предоставляем. Сайт включает следующие компоненты:
                <ul>
                    <li>Предложение персонализированной возможности создавать контент, общаться с другими пользователями, организовывать и планировать профессиональную деятельность хэндлера, осуществлять поиск интересующих вас представителей той или иной породы и сохранять эти данные, организовывать досуг.</li>
                    <li>Создание благоприятной, открытой для всех и безопасной среды.</li>
                </ul>
                Мы используем всю имеющуюся у нас информацию (в том числе вашу информацию), чтобы обеспечивать безопасность нашей платформы. 
                Мы собираем контент, сообщения, локацию и другую информацию, которые вы предоставляете при использовании нашего Сайта, в том числе, когда вы регистрируете аккаунт, создаете контент или делитесь им, отправляете сообщения или взаимодействуете с другими людьми.
                Мы используем имеющуюся у нас информацию для реализации функций нашего Сайта.
                Мы используем информацию о местоположении — например, вашем текущем местоположении, месте проживания, для предоставления, персонализации и улучшения нашего Сайта.
                Мы используем имеющуюся информацию, чтобы общаться с вами по поводу нашего Сайта и сообщать о нашей политике и условиях. Кроме того, информация нужна нам, чтобы отвечать на ваши запросы.
            </p>

            <p>
                В обмен на наше обязательство предоставлять Сайт, вы должны взять на себя следующие обязательства перед нами.
            </p>

            <p>
                Кто может пользоваться порталом Terra-dog.com <br />
                Мы хотим, тобы наш Сайт был максимально открытым и доступным для всех, но вместе с тем безопасным, защищенным и соответствующим требованиям законодательства. Поэтому, чтобы стать частью сообщества terra-dog.com, вы должны соблюдать некоторые ограничения.<br />
                <ul>
                    <li>
                    Ваш аккаунт не должен быть ранее отключен нами за нарушение законодательства или каких-либо наших правил.
                    </li>
                    <li>
                    Вы не имеете права выдавать себя за других людей или указывать неточную информацию.
                    </li>
                </ul>
            </p>

            <p>
                Вы не обязаны раскрывать свою личность на сайте terra-dog, но вы должны предоставить нам точную и актуальную информацию (в том числе регистрационную). Кроме того, вы не имеете права выдавать себя за того, кем вы не являетесь, и создавать аккаунты для других лиц без их прямого разрешения.<br />
                <ul>
                    <li>Вы не имеете права совершать незаконные, вводящие в заблуждение или обманные действия либо иные действия в незаконных или несанкционированных целях.</li>
                    <li>Вы не имеете права нарушать (а также помогать другим нарушать или поощрять нарушения со стороны других лиц) настоящее Пользовательское Соглашение.</li>
                    <li>Вы не имеете права препятствовать нормальной работе Сайта или вмешиваться в его работу.</li>
                    <li>Вы не имеете права предпринимать попытки создания аккаунтов, сбора информации или доступа к ней несанкционированными способами.</li>
                </ul>

                Сюда относятся создание аккаунтов и сбор информации с помощью автоматизированных средств без нашего прямого разрешения.<br />
                <ul>
                    <li>Вы не имеете права предпринимать попытки купить, продать или передать какой-либо компонент вашего аккаунта (в том числе ваше имя пользователя) либо запрашивать, собирать или использовать учетные данные или значки других пользователей.</li>
                    <li>Вы не имеете права публиковать чужую частную или конфиденциальную информацию или совершать какие-либо действия, нарушающие права других лиц, в том числе права на интеллектуальную собственность.</li>
                    <li>Вы не имеете права использовать в своем имени пользователя название домена или URL без нашего предварительного письменного согласия.</li>
                </ul>

                Предоставляемые вами разрешения. В рамках нашего соглашения вы также даете нам разрешения, необходимые для реализации сервисов и материалов Сайта.<br />
                <ul>
                    <li>Мы не претендуем на право собственности на ваш контент, но вы даете нам лицензию на его использование.
                    Ваши права на ваш контент остаются неизменными. Мы не претендуем на права собственности на ваш контент, публикуемый вами на Сайте или с его помощью. Вместо этого, когда вы публикуете или загружаете контент, являющийся объектом прав на интеллектуальную собственность (например, фото), на наш Сайт или в связи с ним или делитесь таким контентом, вы настоящим даете нам неисключительную, не требующую уплаты роялти, действующую по всему миру лицензию с правом передачи и выдачи сублицензий на хранение, использование, распространение, изменение, запуск, копирование, публичное исполнение или показ, перевод вашего контента и создание производных работ на его основе (в соответствии с вашими настройками конфиденциальности и приложения). Вы можете в любое время прекратить действие этой лицензии, удалив свой контент или аккаунт. </li>
                    <li>Разрешение на использование вашего имени пользователя, фото профиля и информации о ваших собаках.</li>
                    <li>Вы даете нам разрешение на показ вашего имени пользователя, фото профиля и информации о ваших собаках, без выплаты вам какого-либо вознаграждения. </li>
                    <li>Вы соглашаетесь с тем, что мы можем скачивать и устанавливать обновления Сайта на ваше устройство.</li>
                </ul>

                Дополнительные права, которые мы сохраняем за собой<br />
                <ul>
                    <li>
                    Если вы выбираете имя пользователя или аналогичное средство идентификации для своего аккаунта, мы оставляем за собой право изменить его, если сочтем это целесообразным или необходимым (например, если оно нарушает чьи-либо права на интеллектуальную собственность или если вы выдаете себя за другого пользователя).
                    </li>
                </ul>

                Удаление контента и отключение или удаление вашего аккаунта<br />
                <ul>
                    <li>Мы вправе удалить любой контент или информацию, которыми вы делитесь на Сайте, если сочтем, что они нарушают настоящее Пользовательское соглашение, либо если это разрешено или предусмотрено требованиями законодательства. Мы можем отказаться или немедленно прекратить предоставлять вам доступ к Сайту полностью или частично (в том числе временно или навсегда прекратить доступ к вашему аккаунту), чтобы защитить наше сообщество или сервисы или если вы создадите риск или неблагоприятные правовые последствия для нас, нарушите условия Пользовательского Соглашения, если вы систематически нарушаете права на интеллектуальную собственность других людей либо если это разрешено или предусмотрено требованиями законодательства. В случае принятия мер по временному или постоянному отключению вашего аккаунта мы уведомим вас, если это будет уместно. Если вы считаете, что ваш аккаунт был отключен по ошибке, либо вы хотите отключить или удалить свой аккаунт, обратитесь в нашу Службу поддержки.</li>
                    <li>Удаленный вами контент может некоторое время храниться в резервных копиях.</li>
                </ul>

                Кто обладает правами по настоящему соглашению.<br />
                <ul>
                    <li>Настоящее соглашение не дает никаких прав третьим лицам.</li>
                    <li>Вам запрещается передавать свои права или обязанности по настоящему соглашению без нашего согласия.</li>
                    <li>Мы вправе уступить наши права и обязанности другим лицам. Например, это может произойти в случае смены собственника или в силу закона.</li>
                </ul>

                Кто понесет ответственность, если что-нибудь случится.<br />
                <ul>
                    <li>Наш Сайт предоставляется "как есть", и мы не можем гарантировать его безопасность, защиту и идеальную работу. </li>
                    <li>Мы не контролируем высказывания и действия людей и других лиц и не несем ответственности за их (или ваши) действия и поведение (в сети Интернет и вне ее) или контент (в том числе незаконный или предосудительный). Кроме того, мы не несем ответственности за сервисы и функции, предлагаемые другими людьми или компаниями, даже если вы осуществляете доступ к ним через наш Сайт.</li>
                    <li>Наша ответственность за все, что происходит на Сайте ограничивается в максимальном объеме, разрешенном законом. В случае возникновения проблем с нашим Сайтом мы не в состоянии предсказать все их возможные последствия. </li>
                    <li>Мы не несем ответственность за любую упущенную прибыль или выручку, потерянную информацию или данные либо за косвенные, штрафные или побочные убытки, возникающие из условий настоящего Пользовательского соглашения или в связи с использованием Сайта, даже если нам было известно о возможности их возникновения. Это положение распространяется также на удаление нами вашего контента, информации или аккаунта.</li>
                </ul>
                
            </p>

            <p>
                Как мы решаем споры.
                <ul>
                    <li>Все возможные споры, вытекающие из текущего Пользовательского соглашения или связанные с ним, подлежат решению согласно действующему законодательству Украины .</li>
                </ul>
            </p>

            <p>
                Материалы, представляемые по вашей инициативе.
            </p>

            <p>
                Мы имеем право использовать ваши отзывы и предложения  без каких-либо ограничений или обязанностей по выплате вам вознаграждения за них, а также не обязаны хранить их в тайне.
            </p>

            <p>
                Обновление настоящих Условий
                Мы можем изменять наш Сайт и Пользовательское соглашение. Если иное не требуется по законодательству, мы будем уведомлять вас перед внесением изменений в настоящие Пользовательское соглашение и давать вам возможность ознакомиться с такими изменениями, прежде чем они вступят в силу. Если после этого вы продолжите использовать Сайт, вы обязаны будете соблюдать обновленное соглашение. Если вы не согласны с настоящим Соглашением или какой-либо обновленной его версией, вы можете удалить свой аккаунт.
            </p>
        </div>
    );
  }
}