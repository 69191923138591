import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './i18n';
import * as serviceWorker from "./serviceWorker";

let relativeUrl = window.location.href.replace(window.location.origin, "");
if (relativeUrl.length > 2) {
    var landingPage = document.getElementById("landingPage");
    var parentLandingPage = document.getElementById("parentLandingPage");
    parentLandingPage.removeChild(landingPage);

    ReactDOM.render(<App />, document.getElementById("root"));
}
else {
    document.getElementById("parentLandingPage").style.display = 'block';
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
