import React, { Component } from "react";
import Octicon, { Location } from "@primer/octicons-react";

export default class LocationButton extends Component {
  render() {
    return (
      <>
        <span
          class="icon mr-2"
          onClick={this.props.onClick}
        >
          <Octicon icon={Location} size="small" />
        </span>
      </>
    );
  }
}
