import React from "react";
import NonAuthComponent from "../../nonAuthComponent";
import ArrowLeftButton from "../../components/arrowLeftButton";
import ArrowRightButton from "../../components/arrowRightButton";
import { withTranslation } from 'react-i18next';

class BreedingAnnouncement extends NonAuthComponent {
  render() {
    const { dogBreeding, t } = this.props;

    return (
      <div className="breedingAnnouncement mb-3 mt-3">
        <div className="dogsNames">
          <h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"/dog/" + dogBreeding.dogId}
            >
              {dogBreeding.dogName}
            </a>{" "}
            & {dogBreeding.matedDog}
          </h4>
        </div>

        <div
          id={"carousel" + dogBreeding.id}
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            {dogBreeding.photos &&
              dogBreeding.photos.length > 0 &&
              dogBreeding.photos.map(photo => (
                <li
                  data-target={"#carousel" + dogBreeding.id}
                  data-slide-to={dogBreeding.photos.indexOf(photo)}
                  class={
                    dogBreeding.photos.indexOf(photo) === 0 ? "active" : ""
                  }
                ></li>
              ))}
          </ol>
          <div class="carousel-inner">
            {dogBreeding.photos &&
              dogBreeding.photos.length > 0 &&
              dogBreeding.photos.map(photo => (
                <div
                  class={
                    "carousel-item" +
                    (dogBreeding.photos.indexOf(photo) === 0 ? " active" : "")
                  }
                >
                  <img
                    src={this.imageUrl + "dogbreedingphotos/" + photo}
                    class="center-block"
                    alt="Новости питомника "
                  ></img>
                </div>
              ))}
          </div>

          {dogBreeding.photos && dogBreeding.photos.length > 1 && (
            <>
              <a
                class="carousel-control-prev"
                href={"#carousel" + dogBreeding.id}
                role="button"
                data-slide="prev"
              >
                <ArrowLeftButton></ArrowLeftButton>
              </a>
              <a
                class="carousel-control-next"
                href={"#carousel" + dogBreeding.id}
                role="button"
                data-slide="next"
              >
                <ArrowRightButton></ArrowRightButton>
              </a>
            </>
          )}
        </div>

        <table class="table table-striped mt-3">
          <colgroup>
            <col style={{ width: "30%" }}></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>{t("Ожидаемая дата родов")}</td>
              <td>{dogBreeding.estimatedСhildBirth}</td>
            </tr>
            <tr>
              <td>{t("Информация о паре")}</td>
              <td>{dogBreeding.breedingInfo}</td>
            </tr>
          </tbody>
        </table>

        <hr></hr>
      </div>
    );
  }
}

export default withTranslation()(BreedingAnnouncement)