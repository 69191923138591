import React from "react";
import NonAuthComponent from "../../nonAuthComponent";

export default class KennelNews extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = {
      news: []
    };
  }

  getNews = () => {
    this.ajaxCall("KennelPublic/GetKennelNews/" + this.props.kennelId, {
      method: "get",
      headers: this.authHeaders
    }).then(response => {
      this.setState({
        news: response
      });
    });
  };

  componentDidMount() {
    this.getNews();
  }

  render() {
    const { news } = this.state;

    return (
      <div id="breedingAnnouncements">
        {news &&
          news.map(newsItem => (
            <div className="pl-3 mt-3 mb-3">
              <h5>{newsItem.timeStamp}</h5>
              <br />{newsItem.text}
              <hr />
            </div>
          ))}
      </div>
    );
  }
}
