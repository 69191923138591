import React from "react";
import AuthComponent from "../../authComponent";
import HandlerCalendar from "./handler/handlerCalendar";
import KennelNews from "./kennel/kennelNews";
import UserMainInfo from "./user/userMainInfo";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'

class Cabinet extends AuthComponent {
  constructor(props) {
    super(props);
    this.state = {
      savedSuccessfully: null,
      showHandlerArea: false,
      showKennelArea: false
    };
  }

  showHandlerArea = () => {
    this.setState({
      showHandlerArea: true
    });
  };

  hideHandlerArea = () => {
    this.setState({
      showHandlerArea: false
    });
  };

  showKennelArea = () => {
    this.setState({
      showKennelArea: true
    });
  };

  hideKennelArea = () => {
    this.setState({
      showKennelArea: false
    });
  };

  successSaving = () => {
    setTimeout(() => {
      this.setState({ savedSuccessfully: false });
    }, 2000);

    this.setState({
      savedSuccessfully: true
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div class="container">
        <div class="one">
          <h1>Кабинет</h1>
        </div>    

        <Helmet>
          <title>Кабинет</title>
          <meta name="description" content="Кабинет пользователя"></meta>
        </Helmet>
        {(this.state.showHandlerArea || this.state.showKennelArea) && (
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-selected="true"
              >
                {t("Личные данные")}
              </a>
            </li>
            {this.state.showHandlerArea && (
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="handler-calendar-tab"
                  data-toggle="pill"
                  href="#handler-calendar"
                  role="tab"
                  aria-selected="false"
                >
                  {t("Календарь хендлера")}
                </a>
              </li>
            )}

            {this.state.showKennelArea && (
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="kennel-news-tab"
                  data-toggle="pill"
                  href="#kennel-news"
                  role="tab"
                  aria-selected="false"
                >
                  {t("Новости питомника")}
                </a>
              </li>
            )}
          </ul>
        )}

        <div class="tab-content" id="pills-tabContent">
          {this.state.savedSuccessfully === true && (
            <div class="alert alert-success" role="alert">
              {t("Сохранение прошло успешно")}
            </div>
          )}
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <UserMainInfo
              successSaving={this.successSaving}
              showHandlerArea={this.showHandlerArea}
              hideHandlerArea={this.hideHandlerArea}
              showKennelArea={this.showKennelArea}
              hideKennelArea={this.hideKennelArea}
            ></UserMainInfo>
          </div>

          {this.state.showHandlerArea && (
            <>
              <div
                class="tab-pane fade"
                id="handler-calendar"
                role="tabpanel"
                aria-labelledby="handler-calendar-tab"
              >
                <HandlerCalendar
                  refreshBreeds={this.state.savedSuccessfully}
                ></HandlerCalendar>
              </div>
            </>
          )}

          {this.state.showKennelArea && (
            <>
              <div
                class="tab-pane fade"
                id="kennel-news"
                role="tabpanel"
                aria-labelledby="kennel-news-tab"
              >
                <KennelNews
                ></KennelNews>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Cabinet)