import React, { Component } from "react";
import "rc-datepicker/lib/style.css";
import { DatePickerInput } from "rc-datepicker";

export default class TerraDatePicker extends Component {
  onChange = (jsDate, dateString) => {
    this.props.onChange(jsDate, dateString);
  }

  render() {
    return (
      <DatePickerInput
        {...this.props}
        readOnly={true}
        value={this.props.selected}
        onChange={this.onChange}
        showOnInputClick={true}
        className="my-custom-datepicker-component"
      />
    );
  }
}
