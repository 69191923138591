import React from "react";
import { Link } from "react-router-dom";
import FavoriteButton from "../components/favoriteButton";
import DiscussionButton from "../components/discussionButton";
import LocationButton from "./locationButton";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import { imageContainerUrl, dogPlaceHolder } from "../apiServer";
import NonAuthComponent from '../nonAuthComponent'
import { withTranslation } from 'react-i18next';

class DogCard extends NonAuthComponent {
  addToFavorite = dogId => {
    ajaxCaller("FavoriteDog/AddFavoriteDog", {
      method: "post",
      headers: authHeaders,
      body: dogId.toString()
    });
  };

  removeFromFavorite = dogId => {
    ajaxCaller("FavoriteDog/DeleteFavoriteDog", {
      method: "post",
      headers: authHeaders,
      body: dogId.toString()
    });

    if (this.props.removedFromTheFavorite != null) {
      this.props.removedFromTheFavorite();
    }
  };

  discussTheDog = dogId => {
    ajaxCaller("Chat/StartChat", {
      method: "post",
      headers: authHeaders,
      body: dogId.toString()
    }).then(response => {
      window.location.href = "/chat/" + response.chatId;
    });
  };

  render() {
    const { dog, t } = this.props;

    return (
      <>
        <div key={dog.id}>
          <div className="card customCard mr-3 mb-3" key={dog.id}>
            {this.currentUserId && dog.myDog === false && (<div className="actionButtons">
              <FavoriteButton
                added={dog.inFavorite}
                onClick={() => {
                  if (dog.inFavorite) {
                    this.removeFromFavorite(dog.id);
                  } else {
                    this.addToFavorite(dog.id);
                  }
                }}
                title={t("Добавить в избранное")}
              ></FavoriteButton>
              <DiscussionButton
                onClick={() => {
                  this.discussTheDog(dog.id);
                }}
              ></DiscussionButton>
            </div>)}
            <img
              src={imageContainerUrl + "dogphotos/" + dog.photo}
              className="card-img"
              alt={dog.breed + " " + (dog.dogName == null || dog.dogName === '' ? t("(Имя не указано)") : dog.dogName) }
              onError={e => (e.target.src = dogPlaceHolder)}
            ></img>

            <div className="card-body">
              <h5 className="card-title">
                <Link className={"nav-link"} to={"/dog/" + dog.id}>
                  {dog.dogName == null || dog.dogName === '' ? t("(Имя не указано)") : dog.dogName }
                </Link>
              </h5>
              <p className="card-text mb-0 pb-0">{dog.breed}</p>

              {dog.birthDate && (
                <p className="card-text mb-0 pb-0">
                  <small>{t("Дата рождения")}: {dog.birthDate}</small>
                </p>
              )}
              {dog.owner && (
                <p className="card-text mb-0 pb-0">
                  <small>{t("Владелец")}: {dog.owner}</small>
                </p>
              )}
              {dog.city && (
                <p className="card-text mb-0 pb-0">
                  <LocationButton></LocationButton>
                  <small> {dog.city}</small>
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(DogCard)