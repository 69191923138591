import React from "react";
import Cookies from "js-cookie";
import AuthComponent from '../../authComponent'
import { withTranslation } from 'react-i18next';

class LogOut extends AuthComponent {
    constructor(props) {
      super(props);
      Cookies.remove('access_token');
      Cookies.remove('logged_in_date');
      window.location.href = '/';
    }
  
    render() {  
      const { t } = this.props;

      return (
        <>
          <div>{t("Выход")}...</div>
        </>
      );
    }
  }

export default withTranslation()(LogOut)
